import T from "prop-types";
import React, { createContext, useState } from "react";

export const defaultProfileDialogContext = {
  open: false,
  setOpen: () => {},
  closeDialog: () => {},
};
const ProfileDialogContext = createContext(defaultProfileDialogContext);

export default ProfileDialogContext;

function ProfileDialogProvider({ children }) {
  const [open, setOpen] = useState(false);

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <ProfileDialogContext.Provider
      value={{
        open,
        setOpen,
        closeDialog,
      }}
    >
      {children}
    </ProfileDialogContext.Provider>
  );
}

ProfileDialogProvider.propTypes = {
  children: T.node,
};

ProfileDialogProvider.defaultProps = {
  children: null,
};

export { ProfileDialogProvider };

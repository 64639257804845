/**
 * Generated by the Inrupt Artifact Generator [@inrupt/artifact-generator], version [3.1.1]
 * as part of artifact: [mou-vocab-ext], version: [0.1.0]
 * on 'Tuesday, January 30, 2024 11:07 AM'.
 *
 * Vocabulary built from vocab list file: [mou-vocab-ext.yml].
 * The generator detected the following terms in the source vocabulary:
 *  - Classes: [0]
 *  - Properties: [8]
 *  - Literals: [0]
 *  - Constant IRIs: [0]
 *  - Constant strings: [0]
 *
 * Slovak national extension adding multilingual meta-data for Legal Subject terms used in MOU project
 *
 * Inrupt Best Practice Compliance Report:
 *  - BP-0: PASS-01 - Vocabulary IRI [https://data.gov.sk/def/ontology/legal-subject-mou-ext/] is different from the namespace IRI [https://data.gov.sk/def/ontology/legal-subject/] (and there was no need to provide overrides for either).
 *  - BP-1: All [8] terms have 'rdfs:isDefinedBy' triples.
 *  - BP-2: All [8] terms that have 'rdfs:isDefinedBy' triples (of the [8] total terms) are defined by the vocabulary IRI of [https://data.gov.sk/def/ontology/legal-subject-mou-ext/].
 */

// We prefix our local variables with underscores to (hopefully!) prevent
// potential names clashes with terms from vocabularies.
const { VocabTerm: _VocabTerm, getLocalStore } = require("@inrupt/solid-common-vocab");
const _DataFactory = require("@rdfjs/data-model");

const { namedNode: _namedNode } = _DataFactory;

function _NS(localName) {
  return _namedNode(`https://data.gov.sk/def/ontology/legal-subject/${localName}`);
}

/**
 * Slovak national extension adding multilingual meta-data for Legal Subject terms used in MOU project
 *
 * Namespace IRI: [https://data.gov.sk/def/ontology/legal-subject/]
 */
const LSUB = {
  PREFIX: "lsub",
  NAMESPACE: "https://data.gov.sk/def/ontology/legal-subject/",
  PREFIX_AND_NAMESPACE: { "lsub": "https://data.gov.sk/def/ontology/legal-subject/" },
  NS: _NS,

  // *******************
  // All the Properties.
  // *******************

  /**
   * oficiálny záznam.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/legal-subject-mou-ext/
   */
  officialRecord: new _VocabTerm(
    _NS("officialRecord"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/legal-subject-mou-ext/"))
    .addLabel(`oficiálny záznam`, "sk")
    .addComment(`oficiálny záznam.`, "sk"),

  /**
   * Informácia o zamestnávateľovi.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/legal-subject-mou-ext/
   */
  employer: new _VocabTerm(
    _NS("employer"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/legal-subject-mou-ext/"))
    .addLabel(`zamestnávateľ`, "sk")
    .addComment(`Informácia o zamestnávateľovi.`, "sk"),

  /**
   * Časové obdobie, kedy bol subjekt zamestnaný.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/legal-subject-mou-ext/
   */
  employmentPeriod: new _VocabTerm(
    _NS("employmentPeriod"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/legal-subject-mou-ext/"))
    .addLabel(`perióda zamestnania`, "sk")
    .addComment(`Časové obdobie, kedy bol subjekt zamestnaný.`, "sk"),

  /**
   * Oficiálne registrované sídlo spoločnosti / právnickej osoby.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/legal-subject-mou-ext/
   */
  hasRegisteredSite: new _VocabTerm(
    _NS("hasRegisteredSite"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/legal-subject-mou-ext/"))
    .addLabel(`má registrované sídlo`, "sk")
    .addComment(`Oficiálne registrované sídlo spoločnosti / právnickej osoby.`, "sk"),

  /**
   * Počet zamestnancov, ktorí vykonávajú práce na dohodu
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/legal-subject-mou-ext/
   */
  numberOfEmployeesByAgreement: new _VocabTerm(
    _NS("numberOfEmployeesByAgreement"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/legal-subject-mou-ext/"))
    .addLabel(`počet zamestnancov na dohodu`, "sk")
    .addComment(`Počet zamestnancov, ktorí vykonávajú práce na dohodu`, "sk"),

  /**
   * Spolu počet zamestnancov
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/legal-subject-mou-ext/
   */
  numberOfEmployeesTotal: new _VocabTerm(
    _NS("numberOfEmployeesTotal"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/legal-subject-mou-ext/"))
    .addLabel(`celkový počet zamestnancov`, "sk")
    .addComment(`Spolu počet zamestnancov`, "sk"),

  /**
   * Počet zamestnancov, ktorí nevykonávajú práce na dohodu
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/legal-subject-mou-ext/
   */
  numberOfRegularEmployees: new _VocabTerm(
    _NS("numberOfRegularEmployees"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/legal-subject-mou-ext/"))
    .addLabel(`počet riadnych zamestnancov`, "sk")
    .addComment(`Počet zamestnancov, ktorí nevykonávajú práce na dohodu`, "sk"),

  /**
   * Hodnota Daňového Identifikačného Čísla
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/legal-subject-mou-ext/
   */
  taxIDCode: new _VocabTerm(
    _NS("taxIDCode"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/legal-subject-mou-ext/"))
    .addLabel(`označenie DIČ`, "sk")
    .addComment(`Hodnota Daňového Identifikačného Čísla`, "sk"),

}

module.exports = LSUB;

/**
 * Generated by the Inrupt Artifact Generator [@inrupt/artifact-generator], version [3.1.1]
 * as part of artifact: [mou-vocab-ext], version: [0.1.0]
 * on 'Tuesday, January 30, 2024 11:07 AM'.
 *
 * Vocabulary built from vocab list file: [mou-vocab-ext.yml].
 * The generator detected the following terms in the source vocabulary:
 *  - Classes: [1]
 *  - Properties: [1]
 *  - Literals: [0]
 *  - Constant IRIs: [0]
 *  - Constant strings: [0]
 *
 * Slovak national extension adding multilingual meta-data for ADMS terms used in MOU project
 *
 * Inrupt Best Practice Compliance Report:
 *  - BP-0: PASS-01 - Vocabulary IRI [https://data.gov.sk/def/ontology/adms-mou-ext/] is different from the namespace IRI [http://www.w3.org/ns/adms#] (and there was no need to provide overrides for either).
 *  - BP-1: All [2] terms have 'rdfs:isDefinedBy' triples.
 *  - BP-2: All [2] terms that have 'rdfs:isDefinedBy' triples (of the [2] total terms) are defined by the vocabulary IRI of [https://data.gov.sk/def/ontology/adms-mou-ext/].
 */

// We prefix our local variables with underscores to (hopefully!) prevent
// potential names clashes with terms from vocabularies.
const { VocabTerm: _VocabTerm, getLocalStore } = require("@inrupt/solid-common-vocab");
const _DataFactory = require("@rdfjs/data-model");

const { namedNode: _namedNode } = _DataFactory;

function _NS(localName) {
  return _namedNode(`http://www.w3.org/ns/adms#${localName}`);
}

/**
 * Slovak national extension adding multilingual meta-data for ADMS terms used in MOU project
 *
 * Namespace IRI: [http://www.w3.org/ns/adms#]
 */
const ADMS = {
  PREFIX: "adms",
  NAMESPACE: "http://www.w3.org/ns/adms#",
  PREFIX_AND_NAMESPACE: { "adms": "http://www.w3.org/ns/adms#" },
  NS: _NS,

  // *****************
  // All the Classes.
  // *****************

  /**
   * This is based on the UN/CEFACT Identifier class.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/adms-mou-ext/, http://www.w3.org/ns/adms
   */
  Identifier: new _VocabTerm(
    _NS("Identifier"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/2002/07/owl#Class"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/adms-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://www.w3.org/ns/adms"))
    .addLabel(`Identifier`, "en")
    .addLabel(`Identifikátor`, "sk")
    .addComment(`Táto trieda je založená na komplexnom type identifikátora UN/CEFACT definovanom v časti 5.8 katalógu základných komponentov, verzia 3.1 (http://www.unece.org/fileadmin/DAM/cefact/codesfortrade/CCTS/CCTS-DTCatalogueVersion3p1.pdf). Hoci to nie je súčasťou modelu ADMS, môže byť užitočné poskytnúť triede identifikátora ďalšie vlastnosti, ako napríklad dcterms:created, aby ste poskytli dátum, kedy bol identifikátor vydaný.`, "sk")
    .addComment(`This is based on the UN/CEFACT Identifier class.`, "en"),


  // *******************
  // All the Properties.
  // *******************

  /**
   * Links a resource to an adms:Identifier class.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/adms-mou-ext/, http://www.w3.org/ns/adms
   */
  identifier: new _VocabTerm(
    _NS("identifier"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/2002/07/owl#ObjectProperty"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/adms-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://www.w3.org/ns/adms"))
    .addLabel(`identifier`, "en")
    .addLabel(`identifikátor`, "sk")
    .addComment(`Používa sa na prepojenie akéhokoľvek zdroja s inštanciou adms:Identifier. ADMS to používa na poskytnutie akéhokoľvek identifikátora zdroja.`, "sk")
    .addComment(`Links a resource to an adms:Identifier class.`, "en"),

}

module.exports = ADMS;

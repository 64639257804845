import PropTypes from "prop-types";
import React, { useCallback, useContext, useMemo } from "react";
import {
  InboxMessageTypes,
  ServicesListContext,
  getIdsFromDataIris,
  isNullOrEmptyString,
  useEnvConfig,
  useInbox,
  AlertContext,
  useWebSocket,
  AllMessagesContext,
  DatasetsListContext,
  useHandleFailedDataTransferMessages,
} from "mou-common";
import { useTranslation } from "react-i18next";
import useIndexer from "../../src/hooks/useIndexer";
import { getToastTextFromMessageData } from "../../src/models/inboxMessage";

export default function InboxMessagesWssProcessor({ children }) {
  const { t } = useTranslation();
  const { fetchInboxMessage } = useInbox();
  const { getMouCmnPrefix } = useEnvConfig();
  const { cacheNewMessage } = useContext(AllMessagesContext);
  const {
    podDatasets,
    reloadSourceServicesActivityStatus,
    sourceServicesActivityStatus,
  } = useContext(DatasetsListContext);
  const { reloadSinkServicesActivityStatus } = useContext(ServicesListContext);
  const { alertInfo } = useContext(AlertContext);
  const { indexInboxMessage, isIndexerEnabled } = useIndexer();
  const { handleFailedDataTransferMessages } =
    useHandleFailedDataTransferMessages(t);

  const moucmnPrefix = useMemo(() => getMouCmnPrefix(), [getMouCmnPrefix]);

  const handleOnInboxMessage = useCallback(
    async (messageContent) => {
      if (!isNullOrEmptyString(messageContent?.path)) {
        const messageId = getIdsFromDataIris(messageContent.path);
        if (isIndexerEnabled) {
          try {
            await indexInboxMessage(messageId[0]);
          } catch (indexError) {
            console.error(indexError);
          }
        }
        const fetchedMsg = await fetchInboxMessage(messageId[0]);
        await cacheNewMessage(messageId, fetchedMsg);
        // if data were downloaded by 3rd party, reload sink services activity status so if
        // active grant was "deactivated" by usage, it will be updated on UI
        if (
          fetchedMsg?.messageType?.["@id"] ===
          InboxMessageTypes.MOU_DATA_TRANSFER_3RD_PARTY_INFORMATION
        ) {
          reloadSinkServicesActivityStatus();
        }
        if (
          fetchedMsg?.messageType?.["@id"] ===
          InboxMessageTypes.MOU_DATA_DATA_TRANSFER_TO_PERSONAL_STORAGE_FAILED_INFORMATION
        ) {
          try {
            const reloadNeeded = await handleFailedDataTransferMessages(
              sourceServicesActivityStatus
            );
            if (reloadNeeded) {
              reloadSourceServicesActivityStatus();
            }
          } catch (e) {
            console.warn(
              `handleOnInboxMessage > handleFailedDataTransferMessages failed`
            );
          }
        }
        const toastMessage = await getToastTextFromMessageData(
          fetchedMsg,
          moucmnPrefix,
          podDatasets
        );
        if (!isNullOrEmptyString(toastMessage)) {
          alertInfo(toastMessage);
        }
      }
    },
    [
      alertInfo,
      cacheNewMessage,
      fetchInboxMessage,
      handleFailedDataTransferMessages,
      indexInboxMessage,
      isIndexerEnabled,
      moucmnPrefix,
      podDatasets,
      reloadSinkServicesActivityStatus,
      reloadSourceServicesActivityStatus,
      sourceServicesActivityStatus,
    ]
  );

  useWebSocket(handleOnInboxMessage);

  return <>{children}</>;
}

InboxMessagesWssProcessor.propTypes = { children: PropTypes.node };

InboxMessagesWssProcessor.defaultProps = { children: null };

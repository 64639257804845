/**
 * Generated by the Inrupt Artifact Generator [@inrupt/artifact-generator], version [3.1.1]
 * as part of artifact: [mou-vocab-ext], version: [0.1.0]
 * on 'Tuesday, January 30, 2024 11:07 AM'.
 *
 * Vocabulary built from vocab list file: [mou-vocab-ext.yml].
 * The generator detected the following terms in the source vocabulary:
 *  - Classes: [0]
 *  - Properties: [3]
 *  - Literals: [0]
 *  - Constant IRIs: [0]
 *  - Constant strings: [0]
 *
 * Slovak national extension adding multilingual meta-data for Schema.org terms used in MOU project
 *
 * Inrupt Best Practice Compliance Report:
 *  - BP-0: PASS-01 - Vocabulary IRI [https://data.gov.sk/def/ontology/schema-mou-ext/] is different from the namespace IRI [http://schema.org/] (and there was no need to provide overrides for either).
 *  - BP-1: All [3] terms have 'rdfs:isDefinedBy' triples.
 *  - BP-2: All [3] terms that have 'rdfs:isDefinedBy' triples (of the [3] total terms) are defined by the vocabulary IRI of [https://data.gov.sk/def/ontology/schema-mou-ext/].
 */

// We prefix our local variables with underscores to (hopefully!) prevent
// potential names clashes with terms from vocabularies.
const { VocabTerm: _VocabTerm, getLocalStore } = require("@inrupt/solid-common-vocab");
const _DataFactory = require("@rdfjs/data-model");

const { namedNode: _namedNode } = _DataFactory;

function _NS(localName) {
  return _namedNode(`http://schema.org/${localName}`);
}

/**
 * Slovak national extension adding multilingual meta-data for Schema.org terms used in MOU project
 *
 * Namespace IRI: [http://schema.org/]
 */
const SCHEMA = {
  PREFIX: "schema",
  NAMESPACE: "http://schema.org/",
  PREFIX_AND_NAMESPACE: { "schema": "http://schema.org/" },
  NS: _NS,

  // *******************
  // All the Properties.
  // *******************

  /**
   * The date when the item becomes valid.
   *
   * This term has [2] labels and comments, in the languages [NoLocale, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/schema-mou-ext/
   */
  validFrom: new _VocabTerm(
    _NS("validFrom"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/schema-mou-ext/"))
    .addLabelNoLanguage(`validFrom`)
    .addLabel(`platné od`, "sk")
    .addComment(`Dátum kedy sa položka stáva platnou.`, "sk")
    .addCommentNoLanguage(`The date when the item becomes valid.`),

  /**
   * The date when the item is no longer valid.
   *
   * This term has [2] labels and comments, in the languages [NoLocale, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/schema-mou-ext/
   */
  validUntil: new _VocabTerm(
    _NS("validUntil"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/schema-mou-ext/"))
    .addLabelNoLanguage(`validUntil`)
    .addLabel(`platné do`, "sk")
    .addComment(`Dátum dokedy je položka platná.`, "sk")
    .addCommentNoLanguage(`The date when the item is no longer valid.`),

  /**
   * The status of the study (enumerated).
   *
   * This term has [2] labels and comments, in the languages [NoLocale, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/schema-mou-ext/
   */
  status: new _VocabTerm(
    _NS("status"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/schema-mou-ext/"))
    .addLabelNoLanguage(`status`)
    .addLabel(`stav`, "sk")
    .addComment(`Stav položky vzhľadom na okolnosti.`, "sk")
    .addCommentNoLanguage(`The status of the study (enumerated).`),

}

module.exports = SCHEMA;

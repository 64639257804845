import PropTypes from "prop-types";
import React, { createContext, useState } from "react";

export const defaultNotificationsDialogContext = {
  open: false,
  setOpen: () => {},
  closeDialog: () => {},
};
const NotificationsDialogContext = createContext(
  defaultNotificationsDialogContext
);

export default NotificationsDialogContext;

function NotificationsDialogProvider({ children }) {
  const [open, setOpen] = useState(null);

  const closeDialog = () => {
    setOpen(null);
  };

  return (
    <NotificationsDialogContext.Provider
      value={{
        open,
        setOpen,
        closeDialog,
      }}
    >
      {children}
    </NotificationsDialogContext.Provider>
  );
}

NotificationsDialogProvider.propTypes = {
  children: PropTypes.node,
};

NotificationsDialogProvider.defaultProps = {
  children: null,
};

export { NotificationsDialogProvider };

/**
 * Generated by the Inrupt Artifact Generator [@inrupt/artifact-generator], version [3.1.1]
 * as part of artifact: [mou-vocab-ext], version: [0.1.0]
 * on 'Tuesday, January 30, 2024 11:07 AM'.
 *
 * Vocabulary built from vocab list file: [mou-vocab-ext.yml].
 * The generator detected the following terms in the source vocabulary:
 *  - Classes: [7]
 *  - Properties: [27]
 *  - Literals: [0]
 *  - Constant IRIs: [0]
 *  - Constant strings: [0]
 *
 * Slovak national extension adding terms used in MOU currently not present in Znalosti portal
 *
 * Inrupt Best Practice Compliance Report:
 *  - BP-0: PASS-01 - Vocabulary IRI [https://data.gov.sk/def/ontology/finance-mou-ext/] is different from the namespace IRI [https://data.gov.sk/def/ontology/finance/] (and there was no need to provide overrides for either).
 *  - BP-1: Only [16] terms have 'rdfs:isDefinedBy' triples, of [34]. Missing [18] (but only displaying the first 5): [registrationPeriod, benefitReceivingPeriod, executionPeriod, basisOfAssessment, isLongTermRegistered].
 *  - BP-2: All [16] terms that have 'rdfs:isDefinedBy' triples (of the [34] total terms) are defined by the vocabulary IRI of [https://data.gov.sk/def/ontology/finance-mou-ext/].
 */

// We prefix our local variables with underscores to (hopefully!) prevent
// potential names clashes with terms from vocabularies.
const { VocabTerm: _VocabTerm, getLocalStore } = require("@inrupt/solid-common-vocab");
const _DataFactory = require("@rdfjs/data-model");

const { namedNode: _namedNode } = _DataFactory;

function _NS(localName) {
  return _namedNode(`https://data.gov.sk/def/ontology/finance/${localName}`);
}

/**
 * Slovak national extension adding terms used in MOU currently not present in Znalosti portal
 *
 * Namespace IRI: [https://data.gov.sk/def/ontology/finance/]
 */
const FIN = {
  PREFIX: "fin",
  NAMESPACE: "https://data.gov.sk/def/ontology/finance/",
  PREFIX_AND_NAMESPACE: { "fin": "https://data.gov.sk/def/ontology/finance/" },
  NS: _NS,

  // *****************
  // All the Classes.
  // *****************

  /**
   * Typ dávky.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  BenefitType: new _VocabTerm(
    _NS("BenefitType"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/2000/01/rdf-schema#Class"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`Typ dávky`, "sk")
    .addComment(`Typ dávky.`, "sk"),

  /**
   * Formálna žiadosť predložená orgánu, inštitúcii alebo organizácii.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  Application: new _VocabTerm(
    _NS("Application"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/2000/01/rdf-schema#Class"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`Žiadosť`, "sk")
    .addComment(`Formálna žiadosť predložená orgánu, inštitúcii alebo organizácii.`, "sk"),

  /**
   * Momentálny status dávok.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  BenefitStatus: new _VocabTerm(
    _NS("BenefitStatus"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/2000/01/rdf-schema#Class"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`Stav dávok`, "sk")
    .addComment(`Momentálny status dávok.`, "sk"),

  /**
   * Momentálny stav role žiadateľa v systéme dávok.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  BenefitRoleStatus: new _VocabTerm(
    _NS("BenefitRoleStatus"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/2000/01/rdf-schema#Class"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`Stav role pri dávkach`, "sk")
    .addComment(`Momentálny stav role žiadateľa v systéme dávok.`, "sk"),

  /**
   * Typ aktualizácie.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  UpdateType: new _VocabTerm(
    _NS("UpdateType"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/2000/01/rdf-schema#Class"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`Typ aktualizácie`, "sk")
    .addComment(`Typ aktualizácie.`, "sk"),

  /**
   * Informácia o dávke.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  Benefit: new _VocabTerm(
    _NS("Benefit"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/2000/01/rdf-schema#Class"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`Dávka`, "sk")
    .addComment(`Informácia o dávke.`, "sk"),

  /**
   * Informácia o vyplatení dávky.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  BenefitPayout: new _VocabTerm(
    _NS("BenefitPayout"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/2000/01/rdf-schema#Class"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`Vyplatenie dávky`, "sk")
    .addComment(`Informácia o vyplatení dávky.`, "sk"),


  // *******************
  // All the Properties.
  // *******************

  /**
   * Daný subjekt má alebo nemá nedoplatky.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  hasArrears: new _VocabTerm(
    _NS("hasArrears"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`má nedoplatky`, "sk")
    .addComment(`Daný subjekt má alebo nemá nedoplatky.`, "sk"),

  /**
   * Informácie o charakteristikách nedoplatku.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  arrearsType: new _VocabTerm(
    _NS("arrearsType"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`typ nedoplatku`, "sk")
    .addComment(`Informácie o charakteristikách nedoplatku.`, "sk"),

  /**
   * Informácie o nedoplatkoch.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  arrears: new _VocabTerm(
    _NS("arrears"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`nedoplatky`, "sk")
    .addComment(`Informácie o nedoplatkoch.`, "sk"),

  /**
   * Časová perióda počas ktorej bol subjekt registrovaný.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  registrationPeriod: new _VocabTerm(
    _NS("registrationPeriod"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`perióda registrácie`, "sk")
    .addComment(`Časová perióda počas ktorej bol subjekt registrovaný.`, "sk"),

  /**
   * Časová perióda počas ktorej subjekt poberal dávky.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  benefitReceivingPeriod: new _VocabTerm(
    _NS("benefitReceivingPeriod"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`perióda poberania dávok`, "sk")
    .addComment(`Časová perióda počas ktorej subjekt poberal dávky.`, "sk"),

  /**
   * Časová perióda počas ktorej subjekt vykonával činnosť alebo aktivitu.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  executionPeriod: new _VocabTerm(
    _NS("executionPeriod"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`perióda vykonávania činnosti`, "sk")
    .addComment(`Časová perióda počas ktorej subjekt vykonával činnosť alebo aktivitu.`, "sk"),

  /**
   * Vymeriavací základ (vykázané vždy v eurách).
   *
   * This term has [1] label and comment, in the language [sk].
   */
  basisOfAssessment: new _VocabTerm(
    _NS("basisOfAssessment"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`vymeriavací základ`, "sk")
    .addComment(`Vymeriavací základ (vykázané vždy v eurách).`, "sk"),

  /**
   * Formálna žiadosť predložená orgánu, inštitúcii alebo organizácii.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  application: new _VocabTerm(
    _NS("application"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`žiadosť`, "sk")
    .addComment(`Formálna žiadosť predložená orgánu, inštitúcii alebo organizácii.`, "sk"),

  /**
   * Informácia o tom či je subjekt dlhodobo registrovaný.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  isLongTermRegistered: new _VocabTerm(
    _NS("isLongTermRegistered"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`je dlhodobo registrovaný`, "sk")
    .addComment(`Informácia o tom či je subjekt dlhodobo registrovaný.`, "sk"),

  /**
   * Časové obdobie dlhodobej registracie subjektu.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  longTermRegistrationPeriod: new _VocabTerm(
    _NS("longTermRegistrationPeriod"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`perióda dlhodobej registrácie`, "sk")
    .addComment(`Časové obdobie dlhodobej registracie subjektu.`, "sk"),

  /**
   * Informácia o poslednej zmene ktorá bola zaevidovaná pre daný subjekt.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  lastUpdate: new _VocabTerm(
    _NS("lastUpdate"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`posledná aktualizácia`, "sk")
    .addComment(`Informácia o poslednej zmene ktorá bola zaevidovaná pre daný subjekt.`, "sk"),

  /**
   * Perióda v ktorej bolo zaznamenané prerušenie.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  interruptionPeriod: new _VocabTerm(
    _NS("interruptionPeriod"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`Doba prerušenia`, "sk")
    .addComment(`Perióda v ktorej bolo zaznamenané prerušenie.`, "sk"),

  /**
   * Informácia o tom či subjekt poberá dávku v nezamestnanosti.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  isClaimingUnemploymentBenefit: new _VocabTerm(
    _NS("isClaimingUnemploymentBenefit"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`poberá dávku v nezamestnanosti`, "sk")
    .addComment(`Informácia o tom či subjekt poberá dávku v nezamestnanosti.`, "sk"),

  /**
   * Informácia o tom či subjekt požaduje dávku v hmotnej núdzi.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  isClaimingBenefitInMaterialNeed: new _VocabTerm(
    _NS("isClaimingBenefitInMaterialNeed"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`žiada dávku v hmotnej núdzi`, "sk")
    .addComment(`Informácia o tom či subjekt požaduje dávku v hmotnej núdzi.`, "sk"),

  /**
   * Informácia o tom akú dávku si subjekt nárokuje, alebo mu bola priznaná.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  claimedBenefit: new _VocabTerm(
    _NS("claimedBenefit"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`nárokovaná/priznaná dávka`, "sk")
    .addComment(`Informácia o tom akú dávku si subjekt nárokuje, alebo mu bola priznaná.`, "sk"),

  /**
   * Informácia o tom, či daný zdroj bol podaný.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  isSubmitted: new _VocabTerm(
    _NS("isSubmitted"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`je podané`, "sk")
    .addComment(`Informácia o tom, či daný zdroj bol podaný.`, "sk"),

  /**
   * Informácia o tom, či daný zdroj je vyplácaný.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  isBeingPaid: new _VocabTerm(
    _NS("isBeingPaid"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`je vyplácané`, "sk")
    .addComment(`Informácia o tom, či daný zdroj je vyplácaný.`, "sk"),

  /**
   * Informácia o tom, či je vyplácaná minimálna finančná výška (napr. príspevku, dávky)
   *
   * This term has [1] label and comment, in the language [sk].
   */
  isMinimalAmount: new _VocabTerm(
    _NS("isMinimalAmount"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`je minimálna suma`, "sk")
    .addComment(`Informácia o tom, či je vyplácaná minimálna finančná výška (napr. príspevku, dávky)`, "sk"),

  /**
   * Informácia o vyplatení dávky.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  benefitPayout: new _VocabTerm(
    _NS("benefitPayout"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`vyplatenie dávky`, "sk")
    .addComment(`Informácia o vyplatení dávky.`, "sk"),

  /**
   * Informácia či je subjekt zároveň držiteľom účtu.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  isAccountHolder: new _VocabTerm(
    _NS("isAccountHolder"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`je držiteľom účtu`, "sk")
    .addComment(`Informácia či je subjekt zároveň držiteľom účtu.`, "sk"),

  /**
   * Informácia či držiteľom účtu je manžel / manželka.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  isSpouseAccountHolder: new _VocabTerm(
    _NS("isSpouseAccountHolder"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`je držiteľom účtu manžel / manželka`, "sk")
    .addComment(`Informácia či držiteľom účtu je manžel / manželka.`, "sk"),

  /**
   * Výška preplatku.
   *
   * This term has [1] label and comment, in the language [sk].
   */
  overpayment: new _VocabTerm(
    _NS("overpayment"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addLabel(`preplatok`, "sk")
    .addComment(`Výška preplatku.`, "sk"),

  /**
   * Dátum, ku ktorému bola suma vyplatená.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  paid: new _VocabTerm(
    _NS("paid"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`dátum vyplatenia`, "sk")
    .addComment(`Dátum, ku ktorému bola suma vyplatená.`, "sk"),

  /**
   * Informácia o celkovej sume, finančnej výške (platby, dávky, pokuty a pod.)
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  totalAmount: new _VocabTerm(
    _NS("totalAmount"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`celková suma`, "sk")
    .addComment(`Informácia o celkovej sume, finančnej výške (platby, dávky, pokuty a pod.)`, "sk"),

  /**
   * Informácia o čiastkových sumách (platby, dávky, pokuty a pod.)
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  subTotalAmount: new _VocabTerm(
    _NS("subTotalAmount"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`medzisúčet`, "sk")
    .addComment(`Informácia o čiastkových sumách (platby, dávky, pokuty a pod.)`, "sk"),

  /**
   * Je osoba, ktorá má dlh voči oprávnenému
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  debtor: new _VocabTerm(
    _NS("debtor"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`dlžík/povinný`, "sk")
    .addComment(`Je osoba, ktorá má dlh voči oprávnenému`, "sk"),

  /**
   * Je osoba, voči ktorej má dlžník nejaký dlh.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/finance-mou-ext/
   */
  creditor: new _VocabTerm(
    _NS("creditor"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/finance-mou-ext/"))
    .addLabel(`veriteľ/oprávnený`, "sk")
    .addComment(`Je osoba, voči ktorej má dlžník nejaký dlh.`, "sk"),

}

module.exports = FIN;

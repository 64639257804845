import T from "prop-types";
import React, { createContext, useState } from "react";

export const defaultDelegationsManagementDialogContext = {
  open: false,
  setOpen: () => {},
  closeDialog: () => {},
};
const DelegationsManagementDialogContext = createContext(
  defaultDelegationsManagementDialogContext
);

export default DelegationsManagementDialogContext;

function DelegationsManagementDialogProvider({ children }) {
  const [open, setOpen] = useState(false);

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <DelegationsManagementDialogContext.Provider
      value={{
        open,
        setOpen,
        closeDialog,
      }}
    >
      {children}
    </DelegationsManagementDialogContext.Provider>
  );
}

DelegationsManagementDialogProvider.propTypes = {
  children: T.node,
};

DelegationsManagementDialogProvider.defaultProps = {
  children: null,
};

export { DelegationsManagementDialogProvider };

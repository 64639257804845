import T from "prop-types";
import React, { createContext, useState } from "react";

export const defaultCancelAccountDialogContext = {
  open: false,
  setOpen: () => {},
  closeDialog: () => {},
};
const CancelAccountDialogContext = createContext(
  defaultCancelAccountDialogContext
);

export default CancelAccountDialogContext;

function CancelAccountDialogProvider({ children }) {
  const [open, setOpen] = useState(null);

  const closeDialog = () => {
    setOpen(null);
  };

  return (
    <CancelAccountDialogContext.Provider
      value={{
        open,
        setOpen,
        closeDialog,
      }}
    >
      {children}
    </CancelAccountDialogContext.Provider>
  );
}

CancelAccountDialogProvider.propTypes = {
  children: T.node,
};

CancelAccountDialogProvider.defaultProps = {
  children: null,
};

export { CancelAccountDialogProvider };

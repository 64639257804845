import {
  InformationSourcesContext,
  HistoryFilterValues,
  ServicesListContext,
  isNullOrEmptyString,
  AllMessagesContext,
  DatasetFilterValues,
  DatasetsListContext,
} from "mou-common";
import PropTypes from "prop-types";
import React, { createContext, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export const defaultDataFilterContext = {
  open: false,
  openFilter: () => {},
  closeFilter: () => {},
  searchValue: "",
  activeServiceFilter: "",
  setSearchValue: () => {},
  activeFilter: null,
  getFilterKeys: (filterData) => [],
  toggleFilter: () => {},
  toggleServiceFilter: () => {},
  clearFilters: () => {},
  filterOptions: [],
  defaultFilterValue: null,
  getLabelForFilterValue: (val) => val,
  filteredData: null,
  totalFilteredCount: null,
  isLoadingFilteredData: false,
};

function DataFilterContextProvider({
  Context,
  getLabelForFilterValue,
  filterOptions,
  defaultFilterValue,
  fetchFilteredData,
  clearFilteredData,
  abortFilteredDataRequest,
  filteredData,
  totalFilteredCount,
  isLoadingFilteredData,
  children,
}) {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [serviceFilter, setServiceFilter] = useState("");
  const [activeFilters, setActiveFilters] = useState({});
  const { informationSourcesByOVM } = useContext(InformationSourcesContext);

  const clearFilters = () => {
    setSearchValue("");
    setServiceFilter("");
    setActiveFilters({});
    // clear filtered messages
    clearFilteredData();
    // abort current filter data request
    abortFilteredDataRequest();
  };

  const filterIsSet = useMemo(
    () => Object.values(activeFilters || {}).some((val) => val),
    [activeFilters]
  );

  // extract only active filter keys from provided filter
  const getFilterKeys = (filterData = {}) =>
    Object.keys(filterData).filter((key) => filterData[key] === true);

  const toggleFilter = (filterKey) => {
    setActiveFilters((prevVal) => {
      const newVal = { ...prevVal };
      newVal[filterKey] = !(prevVal?.[filterKey] || false);
      const allCleared =
        !Object.values(newVal || {}).some((val) => val) && !serviceFilter;
      if (allCleared && isNullOrEmptyString(searchValue)) {
        // clear filtered messages
        clearFilteredData();
        // abort current filter data request
        abortFilteredDataRequest();
      } else {
        fetchFilteredData({
          search: searchValue,
          source: (informationSourcesByOVM[serviceFilter] || []).map(
            (is) => is.id
          ),
          filter: getFilterKeys(newVal),
        });
      }

      return newVal;
    });
  };

  const toggleServiceFilter = (newOvm) => {
    setServiceFilter((prevVal) => {
      const newVal = newOvm !== prevVal ? newOvm : null;
      const allCleared =
        newVal === null &&
        !Object.values(activeFilters || {}).some((val) => val);
      if (allCleared && isNullOrEmptyString(searchValue)) {
        // clear filtered messages
        clearFilteredData();
        // abort current filter data request
        abortFilteredDataRequest();
      } else {
        fetchFilteredData({
          search: searchValue,
          source: (informationSourcesByOVM[newVal] || []).map((is) => is.id),
          filter: getFilterKeys(activeFilters),
        });
      }

      return newVal;
    });
  };

  return (
    <Context.Provider
      value={{
        open,
        openFilter: () => setOpen(true),
        closeFilter: () => setOpen(false),
        searchValue,
        setSearchValue: (val) => {
          if (isNullOrEmptyString(val) && !filterIsSet) {
            clearFilters();
          } else {
            setSearchValue(val);
            fetchFilteredData({
              search: val,
              filter: activeFilters,
              source: (informationSourcesByOVM[serviceFilter] || []).map(
                (is) => is.id
              ),
            });
          }
        },
        activeFilters,
        serviceFilter,
        getFilterKeys,
        toggleFilter,
        toggleServiceFilter,
        clearFilters,
        filterOptions,
        defaultFilterValue,
        getLabelForFilterValue,
        filteredData,
        totalFilteredCount: totalFilteredCount || 0,
        isLoadingFilteredData,
      }}
    >
      {children}
    </Context.Provider>
  );
}

DataFilterContextProvider.propTypes = {
  Context: PropTypes.node.isRequired,
  getLabelForFilterValue: PropTypes.func,
  filterOptions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  defaultFilterValue: PropTypes.string.isRequired,
  fetchFilteredData: PropTypes.func,
  clearFilteredData: PropTypes.func,
  abortFilteredDataRequest: PropTypes.func,
  filteredData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  totalFilteredCount: PropTypes.number,
  isLoadingFilteredData: PropTypes.bool,
  children: PropTypes.node,
};

DataFilterContextProvider.defaultProps = {
  getLabelForFilterValue: (x) => x,
  fetchFilteredData: () => {},
  clearFilteredData: () => {},
  abortFilteredDataRequest: () => {},
  filteredData: null,
  totalFilteredCount: null,
  isLoadingFilteredData: false,
  children: null,
};

const DatasetFilterContext = createContext(defaultDataFilterContext);

function DatasetFilterContextProvider({ children }) {
  const { t } = useTranslation();

  const {
    fetchFilteredDatasets,
    clearFilteredDatasets,
    abortFilteredDatasetsFetchRequest,
    filteredDatasets,
    isLoading,
  } = useContext(DatasetsListContext);
  const { datasetsActivityStatus } = useContext(ServicesListContext);

  const filterOptions = useMemo(
    () =>
      // we don't want to show "all" option in filter options
      Object.values(DatasetFilterValues).filter(
        (val) => val !== DatasetFilterValues.ALL
      ),
    []
  );

  const getLabelForFilterValue = (filterValue) => {
    switch (filterValue) {
      // .ALL case is valid only for sparql version of filtering
      // case DATASET_FILTER_VALUES.ALL:
      //   return t("dataset_filter_all");
      case DatasetFilterValues.SYNCHRONIZED:
        return t("dataset_filter_synchronized");
      case DatasetFilterValues.NOT_SYNCHRONIZED:
        return t("dataset_filter_not_synchronized");
      case DatasetFilterValues.ACCESS_GRANTED:
        return t("dataset_filter_access_granted");
      default:
        return "";
    }
  };

  const totalFilteredCount = useMemo(
    () =>
      [
        ...new Set(
          Object.values(filteredDatasets || {}).map(
            (dt) => dt.metadata?.serviceId
          )
        ),
      ].length,
    [filteredDatasets]
  );

  return DataFilterContextProvider({
    children,
    Context: DatasetFilterContext,
    getLabelForFilterValue,
    filterOptions,
    defaultFilterValue: undefined,
    fetchFilteredData: (filter) =>
      fetchFilteredDatasets(filter, datasetsActivityStatus),
    clearFilteredData: clearFilteredDatasets,
    abortFilteredDataRequest: abortFilteredDatasetsFetchRequest,
    filteredData: filteredDatasets,
    totalFilteredCount,
    isLoadingFilteredData: isLoading,
  });
}

function GeneralHistoryFilterContextProvider({
  Context,
  clearFilteredHistoryMessages,
  filterHistoryMessages,
  abortFilteredInboxFetchRequest,
  filteredData,
  totalFilteredCount,
  isLoadingFilteredData,
  children,
}) {
  const { t } = useTranslation();

  const filterOptions = useMemo(() => Object.values(HistoryFilterValues), []);

  const getLabelForFilterValue = (filterValue) => {
    switch (filterValue) {
      case HistoryFilterValues.UPLOAD_AND_UPDATE:
        return t("history_filter_upload_and_update");
      case HistoryFilterValues.ACCESS_GRANT_CREATE_AND_UPDATE:
        return t("history_filter_access_grant_create_and_update");
      case HistoryFilterValues.DELEGATION_CREATE_AND_UPDATE:
        return t("history_filter_delegation_create_and_update");
      case HistoryFilterValues.ACCESS_GRANT_SHARE_AND_USE:
        return t("history_filter_access_grant_share_and_use");
      default:
        return "";
    }
  };

  return DataFilterContextProvider({
    children,
    Context,
    getLabelForFilterValue,
    filterOptions,
    defaultFilterValue: [],
    fetchFilteredData: filterHistoryMessages,
    clearFilteredData: clearFilteredHistoryMessages,
    abortFilteredDataRequest: abortFilteredInboxFetchRequest,
    filteredData,
    totalFilteredCount,
    isLoadingFilteredData,
  });
}

const NotificationsFilterContext = createContext(defaultDataFilterContext);

function NotificationsFilterContextProvider({ children }) {
  const {
    clearFilteredHistoryMessages,
    filterHistoryMessages,
    abortFilteredInboxFetchRequest,
    filteredHistoryMessages,
    totalFilteredMessagesCount,
    isLoadingFilteredHistoryMessages,
  } = useContext(AllMessagesContext);

  return GeneralHistoryFilterContextProvider({
    children,
    Context: NotificationsFilterContext,
    clearFilteredHistoryMessages,
    filterHistoryMessages,
    abortFilteredInboxFetchRequest,
    filteredData: filteredHistoryMessages,
    totalFilteredCount: totalFilteredMessagesCount,
    isLoadingFilteredData: isLoadingFilteredHistoryMessages,
  });
}

export {
  DatasetFilterContext,
  DatasetFilterContextProvider,
  NotificationsFilterContext,
  NotificationsFilterContextProvider,
};

import T from "prop-types";
import React, { createContext, useState } from "react";

export const defaultDevImageErrorDialogContext = {
  open: false,
  setOpen: () => {},
  closeDialog: () => {},
};
const DevImageErrorDialogContext = createContext(
  defaultDevImageErrorDialogContext
);

export default DevImageErrorDialogContext;

function DevImageErrorDialogProvider({ children }) {
  const [open, setOpen] = useState(null);

  const closeDialog = () => {
    setOpen(null);
  };

  return (
    <DevImageErrorDialogContext.Provider
      value={{
        open,
        setOpen,
        closeDialog,
      }}
    >
      {children}
    </DevImageErrorDialogContext.Provider>
  );
}

DevImageErrorDialogProvider.propTypes = {
  children: T.node,
};

DevImageErrorDialogProvider.defaultProps = {
  children: null,
};

export { DevImageErrorDialogProvider };

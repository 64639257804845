/**
 * Generated by the Inrupt Artifact Generator [@inrupt/artifact-generator], version [3.1.1]
 * as part of artifact: [mou-vocab-ext], version: [0.1.0]
 * on 'Tuesday, January 30, 2024 11:07 AM'.
 *
 * Vocabulary built from vocab list file: [mou-vocab-ext.yml].
 * The generator detected the following terms in the source vocabulary:
 *  - Classes: [2]
 *  - Properties: [0]
 *  - Literals: [0]
 *  - Constant IRIs: [0]
 *  - Constant strings: [0]
 *
 * Slovak national extension adding multilingual meta-data for Core Criterion and Core Evidence Vocabulary used in MOU project
 *
 * Inrupt Best Practice Compliance Report:
 *  - BP-0: PASS-01 - Vocabulary IRI [https://data.gov.sk/def/ontology/m8g-mou-ext/] is different from the namespace IRI [http://data.europa.eu/m8g/] (and there was no need to provide overrides for either).
 *  - BP-1: All [2] terms have 'rdfs:isDefinedBy' triples.
 *  - BP-2: All [2] terms that have 'rdfs:isDefinedBy' triples (of the [2] total terms) are defined by the vocabulary IRI of [https://data.gov.sk/def/ontology/m8g-mou-ext/].
 */

// We prefix our local variables with underscores to (hopefully!) prevent
// potential names clashes with terms from vocabularies.
const { VocabTerm: _VocabTerm, getLocalStore } = require("@inrupt/solid-common-vocab");
const _DataFactory = require("@rdfjs/data-model");

const { namedNode: _namedNode } = _DataFactory;

function _NS(localName) {
  return _namedNode(`http://data.europa.eu/m8g/${localName}`);
}

/**
 * Slovak national extension adding multilingual meta-data for Core Criterion and Core Evidence Vocabulary used in MOU project
 *
 * Namespace IRI: [http://data.europa.eu/m8g/]
 */
const CCCEV = {
  PREFIX: "cccev",
  NAMESPACE: "http://data.europa.eu/m8g/",
  PREFIX_AND_NAMESPACE: { "cccev": "http://data.europa.eu/m8g/" },
  NS: _NS,

  // *****************
  // All the Classes.
  // *****************

  /**
   * Proof that a Requirement is met.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/m8g-mou-ext/, http://data.europa.eu/m8g
   */
  Evidence: new _VocabTerm(
    _NS("Evidence"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/2000/01/rdf-schema#Class"))
    .addType(_namedNode("http://www.w3.org/2002/07/owl#Class"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/m8g-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://data.europa.eu/m8g"))
    .addLabel(`Evidence`, "en")
    .addLabel(`Dôkaz`, "sk")
    .addComment(`Dôkaz, že je splnená požiadavka.`, "sk")
    .addComment(`Proof that a Requirement is met.`, "en"),

  /**
   * Information about the characteristics of an Evidence.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/m8g-mou-ext/, http://data.europa.eu/m8g
   */
  EvidenceType: new _VocabTerm(
    _NS("EvidenceType"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/2000/01/rdf-schema#Class"))
    .addType(_namedNode("http://www.w3.org/2002/07/owl#Class"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/m8g-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://data.europa.eu/m8g"))
    .addLabel(`Evidence Type`, "en")
    .addLabel(`Typ dôkazu`, "sk")
    .addComment(`Informácie o charakteristikách dôkazu.`, "sk")
    .addComment(`Information about the characteristics of an Evidence.`, "en"),

}

module.exports = CCCEV;

import {
  useSession,
  ServiceMoucmnTypes,
  UsageLimitMouvcTypes,
  useEnvConfig,
  usePodFromProfile,
  useSessionFetch,
  isNullOrEmptyString,
  joinPath,
  ServiceTypes,
  useLinkedServicesApi,
  DATASETS_CONTAINER,
  USERCONFIG_SUFFIX,
  JsonLdCacheContext,
  DATASETS_PATH,
  DelegationContext,
  DatasetsListContext,
  useConsents,
  useNotifications,
} from "mou-common";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import LinkedServicesContext from "../../contexts/linkedServicesContext";

const CTX_TYPE_LINKED_SERVICE = "LinkedService";
const CTX_TYPE_ACCESS_GRANT = "AccessGrant";

const useServiceLinks = () => {
  const { t } = useTranslation();
  const { getMouContextUrl, getDefaultServicesIds } = useEnvConfig();
  const { fetchLinkedServices, saveLinkedServices } = useLinkedServicesApi();
  const { podDatasets } = useContext(DatasetsListContext);
  const { pod } = usePodFromProfile();
  const [isLoading, setIsLoading] = useState(false);
  const { session } = useSession();
  const { fetch: sessionFetch } = useSessionFetch();
  const {
    issueAccessGrantForSourceService,
    revoke,
    verifyAccessGrants,
    issueUpdatedSinkServiceAccessGrant,
    revokeDelegations,
  } = useConsents(t);

  const { info } = session;

  const { getJsonLdContent } = useContext(JsonLdCacheContext);
  const { linkedServices: linkedServicesContext } = useContext(
    LinkedServicesContext
  );
  const { getActiveProvidedDelegationsForDatasets } =
    useContext(DelegationContext);

  const { sendDataDeletedInformation } = useNotifications(t);

  const getGrantIdFromGrant = (grant) => (grant["@id"] || "").split("/").at(-1);

  /**
   * Delete dataset user config
   * @param {string} datasetId
   */
  const deleteDatasetUserConfig = async (datasetId) => {
    try {
      const datasetsIri = joinPath(
        pod,
        DATASETS_CONTAINER,
        `${datasetId}${USERCONFIG_SUFFIX}`
      );
      console.log(`Delete datase user config ${datasetsIri}`);
      await sessionFetch(datasetsIri, {
        method: "DELETE",
      });
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
    return Promise.resolve();
  };

  /**
   * Revokes specific access grants for service.
   *
   * @param {String} grantId
   * @param {String} serviceId
   * @param {boolean} isSink
   */
  const revokeAccessGrant = async (grantId, serviceId, isSink = false) => {
    try {
      if (isNullOrEmptyString(grantId) || isNullOrEmptyString(serviceId)) {
        return Promise.reject(
          new Error(
            "Cannot revoke consent, missing serviceId or grantId",
            serviceId,
            grantId
          )
        );
      }
      await revoke(grantId, serviceId, isSink);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(
        new Error(
          `Cannot revoke consent for service ${serviceId}: ${error.message}`
        )
      );
    }
  };

  /**
   * Delete dataset that has been synced
   * @param {string} datasetId
   */
  const deleteDataset = async (datasetId) => {
    try {
      await deleteDatasetUserConfig(datasetId);
      const datasetsIri = joinPath(pod, DATASETS_CONTAINER, datasetId);
      console.log(`Delete dataset ${datasetsIri}`);
      await sessionFetch(datasetsIri, {
        method: "DELETE",
      });
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
    return Promise.resolve();
  };

  const getServiceDatasets = (serviceId) => {
    const eligibleDatasets = Object.values(podDatasets || {}).filter(
      (d) => d.metadata?.serviceId === serviceId && d.metadata?.datasetId
    );
    // Return just datasetId
    return eligibleDatasets.map((d) => d.metadata.datasetId);
  };

  /**
   * Revoke sink service for deleted datasets
   * @param {string} credentialId
   * @param {string[]} deletedDatasetsUris
   * @param {string} vcPrefix
   */
  const revokeSinkServiceForDeletedDatasets = async (
    credentialId,
    deletedDatasetsUris,
    vcPrefix
  ) => {
    let revokeAG = false;
    const vc = await getJsonLdContent(joinPath(vcPrefix, credentialId));
    const forPersonalData =
      vc?.credentialSubject?.providedConsent?.forPersonalData || [];
    const extractedDatasetUris = forPersonalData.map((data) => {
      const dataParts = data.split("/");
      const extractedDatasetUri = dataParts[dataParts.length - 1];
      return extractedDatasetUri;
    });

    if (extractedDatasetUris.length > 0) {
      const isSubset = extractedDatasetUris.every((item) =>
        deletedDatasetsUris.includes(item)
      );

      if (isSubset) {
        revokeAG = true;
      } else {
        const datasetsIds = extractedDatasetUris.reduce((acc, value) => {
          if (!deletedDatasetsUris.includes(value)) {
            acc.push(value);
          }
          return acc;
        }, []);

        if (datasetsIds.length !== extractedDatasetUris.length) {
          if (datasetsIds.length > 0) {
            try {
              for await (const datasetId of datasetsIds) {
                const datasetPath = joinPath(
                  pod,
                  DATASETS_PATH.substring(0, DATASETS_PATH.length - 1),
                  datasetId
                );
                const responseDataset = await sessionFetch(datasetPath);
                if (responseDataset.status === 200) {
                  // dataset exists in another access grant, do not revoke
                  return;
                }

                if (responseDataset.status === 404) {
                  // dataset does not exist in another access grant, revoke flag = true
                  revokeAG = true;
                } else {
                  // TODO: handle other status codes
                  console.warn(
                    "Response status code: ",
                    responseDataset.status
                  );
                  return;
                }
              }
            } catch (e) {
              console.error("canRevokeArr error: ", e);
            }
          }
        }
      }
      if (revokeAG) {
        await revokeAccessGrant(
          vc.id,
          vc.credentialSubject.providedConsent.serviceId,
          true
        );
      }
    }
  };

  /**
   * Revoke sink access grant if possible
   * @param {string[[]]} verifiedTrueCredentialIds
   * @param {string[]} deletedDatasetsUris
   * @param {string} vcPrefix
   */
  const revokeSinkAccessGrantsIfPossible = async (
    verifiedTrueCredentialIds,
    deletedDatasetsUris,
    vcPrefix
  ) => {
    for await (const credentialIds of verifiedTrueCredentialIds) {
      for await (const credentialId of credentialIds) {
        revokeSinkServiceForDeletedDatasets(
          credentialId,
          deletedDatasetsUris,
          vcPrefix
        );
      }
    }
  };

  /**
   * Get all verified true ids and prefix from sink access grants
   * @returns
   * @param {string} serviceId
   * @param {string} datasetId
   * @param {boolean} revokeSinkAccessGrantIfPossible
   * @returns
   */
  const getValidAccessGrants = async () => {
    let vcPrefix = "";
    const trueIds = linkedServicesContext
      .filter((ls) => ls.serviceType === ServiceMoucmnTypes.SINK)
      .map(async (linkedService) => {
        const potentionalCredentialIds = linkedService.accessGrants;
        const credentialIds = potentionalCredentialIds.map((id) => {
          const credentialUriParts = id["@id"].split("/");
          const credentialId =
            credentialUriParts[credentialUriParts.length - 1];
          vcPrefix = credentialUriParts
            .slice(0, credentialUriParts.length - 1)
            .join("/");
          return credentialId;
        });

        const bulkVerifyResult = await verifyAccessGrants(
          credentialIds,
          linkedServicesContext
        );

        const verifiedTrueCredentialIds = Object.entries(bulkVerifyResult)
          .filter(([id, value]) => value === true)
          .map(([id]) => id);
        return verifiedTrueCredentialIds;
      });

    try {
      const verifiedCredentialIds = await Promise.all(trueIds);
      return { verifiedCredentialIds, vcPrefix };
    } catch (e) {
      console.error("getTrueIdsAndPrefix error: ", e);
      return {};
    }
  };

  /**
   * Delete all datasets that have been synced
   * @param {string} serviceId
   */
  const deleteServiceDatasets = async (serviceId, sendNotification = true) => {
    try {
      const datasetIds = getServiceDatasets(serviceId);
      await Promise.all(
        datasetIds.map((datasetId) => {
          return deleteDataset(datasetId);
        })
      );

      const validAccessGrants = await getValidAccessGrants();
      await revokeSinkAccessGrantsIfPossible(
        validAccessGrants.verifiedCredentialIds,
        datasetIds,
        validAccessGrants.vcPrefix
      );
      const delegationsToRevoke =
        getActiveProvidedDelegationsForDatasets(datasetIds);
      await revokeDelegations(delegationsToRevoke);
      if (sendNotification) {
        try {
          await sendDataDeletedInformation(
            serviceId,
            datasetIds.map((datasetId) => datasetId)
          );
        } catch (e) {
          console.error(
            `deleteServiceDatasets > failed to send notification: ${e.message}`
          );
        }
      }
    } catch (error) {
      console.error(`deleteServiceDatasets > ${error.message}`);
      return Promise.resolve(false);
    }
    return Promise.resolve(true);
  };

  /**
   * Revokes all access grants for service.
   *
   * @param {[service]} linkedServices
   * @param {*} serviceId
   */
  const revokeAccessGrantMulti = async (
    linkedServices,
    serviceId,
    isSink = false
  ) => {
    try {
      const linkedServiceIdx = linkedServices.findIndex(
        (sl) => sl.serviceId === serviceId
      );
      // Assume we always find
      if (
        linkedServiceIdx > -1 &&
        (linkedServices[linkedServiceIdx].accessGrants || []).length > 0
      ) {
        // determine which grants has to be revoked for provided service
        const grantIds = (
          linkedServices[linkedServiceIdx].accessGrants || []
        ).map((grant) => getGrantIdFromGrant(grant));
        const verifyResult = await verifyAccessGrants(grantIds, linkedServices);
        const activeGrants = (
          linkedServices[linkedServiceIdx].accessGrants || []
        ).filter((grant) => verifyResult[getGrantIdFromGrant(grant)] === true);
        // revoke all active grants
        await Promise.allSettled(
          activeGrants.map((ag) => revoke(ag["@id"], serviceId, isSink))
        ); // TODO: How handle multiple if one fails, ignore at the moment
        return Promise.resolve();
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(
        new Error(
          `Cannot revoke consent for service ${serviceId}: ${error.message}`
        )
      );
    }
  };

  /**
   * Remove service id from profile/linked_services
   * @param {string} serviceId
   * @returns
   */
  const unlinkService = async (
    serviceId,
    datasetId,
    sendNotification = true
  ) => {
    console.log(`Unlink service ${serviceId}, pod ${pod}`);
    if (pod && serviceId) {
      try {
        setIsLoading(true);
        const linkedServices = await fetchLinkedServices();
        const linkedService = linkedServices.filter(
          (sl) => sl.serviceId === serviceId
        )[0];

        if (linkedService) {
          await revokeAccessGrantMulti(linkedServices, serviceId);
          await deleteServiceDatasets(serviceId, sendNotification);
        } else if (datasetId) {
          // TODO: Do we need this? If dataset exists but no entry in linked_services
          try {
            await deleteDataset(datasetId);
            // eslint-disable-next-line no-empty
          } catch {}
        }
        return Promise.resolve(`Unlink of serviceId ${serviceId} successful!`);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      } finally {
        setIsLoading(false);
      }
    }
    setIsLoading(false);
    return Promise.reject();
  };

  const unlinkSinkService = async (serviceId, remove = false) => {
    if (pod && serviceId) {
      try {
        setIsLoading(true);
        const linkedServices = await fetchLinkedServices();
        const linkedService = linkedServices.filter(
          (sl) => sl.serviceId === serviceId
        )[0];

        if (linkedService) {
          await revokeAccessGrantMulti(linkedServices, serviceId, true);
        }
        setIsLoading(false);
        return Promise.resolve(`Unlink of serviceId ${serviceId} successful!`);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
        return Promise.reject(error);
      }
    }
    setIsLoading(false);
    return Promise.reject();
  };

  const linkedServiceInfo = async (serviceId) => {
    try {
      setIsLoading(true);
      const linkedServices = await fetchLinkedServices();
      const linkedServiceEntry = linkedServices.find(
        (ls) => ls.serviceId === serviceId
      );
      setIsLoading(false);
      return linkedServiceEntry;
    } catch (error) {
      console.error(`linkedServiceInfo error:`, error);
    }
    setIsLoading(false);
    return undefined;
  };

  const linkService = async (
    serviceId,
    serviceType = ServiceMoucmnTypes.SOURCE,
    usageLimit = UsageLimitMouvcTypes.ONE_TIME,
    isGrantUpdate = false,
    isForcedUpdate = false,
    // if set to true, it will link service only if no previous access grant (even invalidated) was found
    isDefaultLinking = false
  ) => {
    if (!serviceId) {
      return Promise.reject(new Error("error_link_missing_service_id"));
    }
    setIsLoading(true);
    const result = [];
    try {
      const linkedServices = await fetchLinkedServices();
      let linkedServiceEntry = linkedServices.find(
        (ls) => ls.serviceId === serviceId
      );
      console.log(`Found linked service entry: `, linkedServiceEntry);
      if (!linkedServiceEntry) {
        linkedServiceEntry = {
          "@context": getMouContextUrl(),
          "@type": CTX_TYPE_LINKED_SERVICE,
          serviceType,
          serviceId,
          accessGrants: [],
        };

        linkedServices.push(linkedServiceEntry);
        result.push("linking");
        await saveLinkedServices(linkedServices);
      }

      if ((linkedServiceEntry.accessGrants || []).length === 0) {
        console.log(
          `Linked service entry does not have access grant, issue it now.`
        );
        const accessGrant = await issueAccessGrantForSourceService(
          serviceId,
          usageLimit
        );
        if (accessGrant) {
          // Save access grant it to profile/linked_services corresponding entry
          const linkedServiceIdx = linkedServices.findIndex(
            (ls) => ls.serviceId === serviceId
          );
          if (linkedServiceIdx > -1) {
            linkedServices[linkedServiceIdx].accessGrants = [
              {
                "@id": accessGrant.id,
                "@type": CTX_TYPE_ACCESS_GRANT,
                dateCreated: accessGrant.issuanceDate, // Take from access grant
                usageLimit,
              },
            ];
            await saveLinkedServices(linkedServices);
          }
          result.push("consent");
        }
      } else if (
        (linkedServiceEntry.accessGrants || []).length > 0 &&
        !isDefaultLinking
      ) {
        console.log(
          `Service has existing access grant(s) in linked_services data, checking if new grant is required.`
        );
        const grantIds = linkedServiceEntry.accessGrants.map((grant) =>
          getGrantIdFromGrant(grant)
        );
        const verifyResult = await verifyAccessGrants(grantIds, linkedServices);
        const activeGrant = linkedServiceEntry.accessGrants.find(
          (grant) => verifyResult[getGrantIdFromGrant(grant)] === true
        );
        if (!activeGrant) {
          console.log(
            `Linked service entry does have only invalid access grant(s), issue new one.`
          );
          const accessGrant = await issueAccessGrantForSourceService(
            serviceId,
            usageLimit
          );
          if (accessGrant) {
            // Save access grant it to profile/linked_services corresponding entry
            const linkedServiceIdx = linkedServices.findIndex(
              (ls) => ls.serviceId === serviceId
            );
            if (linkedServiceIdx > -1) {
              linkedServices[linkedServiceIdx].accessGrants.push({
                "@id": accessGrant.id,
                "@type": CTX_TYPE_ACCESS_GRANT,
                dateCreated: accessGrant.issuanceDate, // Take from access grant
                usageLimit,
              });
              await saveLinkedServices(linkedServices);
            }
            result.push("another_consent");
          }
        } else if (activeGrant && isGrantUpdate) {
          console.log(
            `Linked service has valid access grant, but issue new one.`
          );
          // if some active grant was found and we are in access grant update mode
          // issue new access grant
          const newGrant = await issueAccessGrantForSourceService(
            serviceId,
            usageLimit
          );
          result.push("updated_consent");
          if (newGrant && !isForcedUpdate) {
            // Save access grant it to profile/linked_services corresponding entry
            const linkedServiceIdx = linkedServices.findIndex(
              (ls) => ls.serviceId === serviceId
            );
            if (linkedServiceIdx > -1) {
              linkedServices[linkedServiceIdx].accessGrants.push({
                "@id": newGrant.id,
                "@type": CTX_TYPE_ACCESS_GRANT,
                dateCreated: newGrant.issuanceDate,
                usageLimit,
              });
              await saveLinkedServices(linkedServices);
            }
            console.log(
              `Now revoke the former active access grant ${activeGrant["@id"]}.`
            );
            // revoke old access grant
            await revoke(activeGrant["@id"], serviceId, false);
          }
        }
      } else {
        console.log(`Linking to service ${serviceId} is not needed.`);
      }

      setIsLoading(false);
      console.log(`Linking result: ${result}`);
      return Promise.resolve(result);
    } catch (error) {
      setIsLoading(false);
      console.error(`Linking failed: ${error}`);
      return Promise.reject(error);
    }
  };

  const linkSinkServiceWithAccessInfo = async (
    serviceId,
    accessGrant,
    serviceType = ServiceMoucmnTypes.SINK,
    usageLimit = UsageLimitMouvcTypes.ONE_TIME
  ) => {
    if (!serviceId) {
      return Promise.reject(new Error("error_link_missing_service_id"));
    }
    const result = [];
    try {
      const linkedServices = await fetchLinkedServices();
      let linkedServiceEntry = linkedServices.find(
        (ls) => ls.serviceId === serviceId
      );
      // if service isn't linked yet, make link entry
      if (!linkedServiceEntry) {
        // TODO: get actual surrogate ID
        const surrogateId = info.webId || "";
        if (surrogateId) {
          linkedServiceEntry = {
            "@context": getMouContextUrl(),
            "@type": CTX_TYPE_LINKED_SERVICE,
            serviceType,
            serviceId,
            accessGrants: [],
          };
          linkedServices.push(linkedServiceEntry);
          result.push("linking");
          await saveLinkedServices(linkedServices);
        }
      }

      // add consent entry to service link
      const existingAccessGrants = linkedServiceEntry.accessGrants || [];
      const linkedServiceIdx = linkedServices.findIndex(
        (ls) => ls.serviceId === serviceId
      );
      if (linkedServiceIdx > -1) {
        linkedServices[linkedServiceIdx].accessGrants = [
          ...existingAccessGrants,
          {
            "@id": accessGrant.id,
            "@type": CTX_TYPE_ACCESS_GRANT,
            dateCreated: accessGrant.issuanceDate, // Take from access grant
            usageLimit,
          },
        ];
        await saveLinkedServices(linkedServices);
      }
      result.push("consent");

      console.log(`Linking result: ${result}`);
      return Promise.resolve(result);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  const updateSinkServiceAccessGrant = async (
    serviceId,
    oldGrantData,
    usageLimit,
    expirationDate
  ) => {
    if (!serviceId || !oldGrantData) {
      console.error(
        "Error updating sink service accessGrant, missing serviceId or oldGrantData: ",
        serviceId,
        oldGrantData
      );
      return Promise.reject(new Error("error_grant_update_missing_data"));
    }
    try {
      setIsLoading(true);
      const result = [];
      const linkedServices = await fetchLinkedServices();
      const linkedServiceEntry = linkedServices.find(
        (ls) => ls.serviceId === serviceId
      );
      // there should always be some linked service entry in this case as we are updating existing grant
      if (linkedServiceEntry) {
        const newAccessGrantVc = await issueUpdatedSinkServiceAccessGrant(
          oldGrantData,
          usageLimit,
          expirationDate
        );
        const existingAccessGrants = linkedServiceEntry.accessGrants || [];
        const linkedServiceIdx = linkedServices.findIndex(
          (ls) => ls.serviceId === serviceId
        );
        if (linkedServiceIdx > -1) {
          linkedServices[linkedServiceIdx].accessGrants = [
            ...existingAccessGrants,
            {
              "@id": newAccessGrantVc.id,
              "@type": CTX_TYPE_ACCESS_GRANT,
              dateCreated: newAccessGrantVc.issuanceDate, // Take from access grant
              usageLimit,
            },
          ];
          await saveLinkedServices(linkedServices);
        }
        result.push("updated_consent");
        // revoke old access grant, mark isSink as false so recipients aren't removed in this case
        await revoke(oldGrantData.id, serviceId, false, true);
        return Promise.resolve(result);
      }
      // if linked service entry wasn't found, reject operation..
      console.error(
        `Error updating sink service accessGrant, service entry for service "${serviceId}" in linked services: `,
        linkedServices
      );
      return Promise.reject(
        new Error("error_grant_update_missing_linked_service_entry")
      );
    } catch (e) {
      console.error("Unexpected updateSinkServiceAccessGrant error: ", e);
      return Promise.reject(e);
    } finally {
      setIsLoading(false);
    }
  };

  const unlinkAll = async (sendDeleteNotification = false, remove = false) => {
    setIsLoading(true);
    try {
      const linkedServices = await fetchLinkedServices();
      await Promise.all(
        linkedServices.map((service) => {
          if (service.type === ServiceTypes.SINK) {
            return unlinkSinkService(service.serviceId, remove);
          }
          return unlinkService(service.serviceId, null, sendDeleteNotification);
        })
      );
      return Promise.resolve();
    } catch {
      return Promise.reject();
    }
  };

  const linkDefaultServices = async () => {
    const defaultServicesIds = getDefaultServicesIds();
    for await (const servId of defaultServicesIds) {
      await linkService(servId, undefined, undefined, false, false, true);
    }
  };

  return {
    deleteDataset,
    linkService,
    linkedServiceInfo,
    unlinkService,
    unlinkSinkService,
    unlinkAll,
    isLoading,
    linkSinkServiceWithAccessInfo,
    updateSinkServiceAccessGrant,
    fetchLinkedServices,
    revokeAccessGrant,
    linkDefaultServices,
  };
};

export default useServiceLinks;

import PropTypes from "prop-types";
import React, { createContext, useRef } from "react";
import fetch from "unfetch";
import VOCABS from "@compote/mou-vocab-ext";
import {
  isNullOrEmptyArray,
  isNullOrEmptyString,
  KnowledgeBaseGovAttributes,
} from "mou-common";

export const defaultVisualizationLabelsCacheContext = {
  getLabel: () => {},
};

const LOADING_STATUS = "data_loading_status";
const FAILED_STATUS = "data_failed_status";
const LOAD_AWAIT_TIMEOUT = 30000;
const LOAD_AWAIT_INTERVAL = 50;

const GOV_DATA_PREFIX = "https://data.gov.sk/def/";
const GOV_ID_PREFIX = "https://data.gov.sk/id/";
const GOV_DATA_API = "https://znalosti.gov.sk/api/resource";
const GOV_PREDICATE_KEY = "predicateShort";
const GOV_VALUE_KEY = "object";
const GOV_LABEL_PREDICATE_VALUE = "rdfs:label";

const VisualizationLabelsCacheContext = createContext(
  defaultVisualizationLabelsCacheContext
);

function VisualizationLabelsCacheContextProvider({ children, defaultCache }) {
  // use 'useRef' hook instead of 'useState' as it can be directly mutated in 'getLabel' function
  const cachedData = useRef(defaultCache || {});

  const fetchGovData = async (
    uri,
    labelAttribute = KnowledgeBaseGovAttributes.LABEL,
    altLabelAttribute = KnowledgeBaseGovAttributes.ALT_LABEL
  ) => {
    const url = `${GOV_DATA_API}?uri=${uri}&content-type=application%2Fld%2Bjson`;
    const response = await fetch(url, {
      method: "GET",
    });
    const responseJson = await response.json();
    // TODO: if comment below is deleted, remove also getStringFromThing fn from solidClientHelpers/resource
    // TODO: improve parsing so fetchGovData fn can wait for actual parse and set label correctly
    //  for now jsonLdToDataset supports only callbacks which isn't working in this case..
    // await jsonLdToDataset(responseJson[0], (rdfJsDt, solidDt) => {
    //   if (solidDt) {
    //     try {
    //       const label = chain(
    //         solidDt,
    //         // root thing is defined by uri we've been looking for
    //         (data) => getThing(data, uri),
    //         (rootThing) => {
    //           let labelString = getStringFromThing(
    //             rootThing,
    //             KnowledgeBaseGovAttributes.LABEL
    //           );
    //           if (!labelString) {
    //             labelString = getStringFromThing(
    //               rootThing,
    //               KnowledgeBaseGovAttributes.ALT_LABEL
    //             );
    //           }
    //           return labelString;
    //         }
    //       );
    //       if (label) {
    //         const capitalized = label.charAt(0).toUpperCase() + label.slice(1);
    //         return capitalized;
    //       }
    //     } catch (e) {
    //       console.error(`Couldn't get (alt)label for attribute ${uri}: `, e);
    //     }
    //   }
    // });

    // TODO: remove "direct" label getting when above TODO is fixed
    if ((responseJson || []).length > 0) {
      // some data.gov urls (e.g. https://data.gov.sk/def/ontology/location/physicalAddress) returns multiple entries in
      // response array so find one where @id matches url to get correct label
      const dataWithId = responseJson.find((dt) => dt["@id"] === uri);
      const dataObj = dataWithId || responseJson[0];
      let labelArr = dataObj[labelAttribute];
      if (isNullOrEmptyArray(labelArr) && altLabelAttribute) {
        labelArr = dataObj[altLabelAttribute];
      }
      const labelItem = (labelArr || []).find(
        (lab) => lab["@language"] === "sk"
      );
      if (labelItem && !isNullOrEmptyString(labelItem["@value"])) {
        const label = labelItem["@value"];
        const capitalized = label.charAt(0).toUpperCase() + label.slice(1);
        return capitalized;
      }
    }

    return uri;
  };

  const getLabelFromVocabulary = (labelUri, locale = "sk") => {
    if (isNullOrEmptyString(labelUri)) {
      return labelUri;
    }
    const specificVocab = Object.values(VOCABS).find((vocab) =>
      (labelUri || "").startsWith(vocab.NAMESPACE)
    );
    if (specificVocab) {
      const splitter = specificVocab.NAMESPACE.slice(-1);
      const termKey = labelUri.split(splitter).slice(-1)[0];
      if (specificVocab[termKey]) {
        const label = specificVocab[termKey]?.asLanguage(locale).label;
        const capitalized = label.charAt(0).toUpperCase() + label.slice(1);
        return capitalized;
      }
    }
    return labelUri;
  };

  const getLabel = async (uri) => {
    if (isNullOrEmptyString(uri)) {
      return "";
    }
    const data = cachedData.current[uri];
    if (data && data !== LOADING_STATUS && data !== FAILED_STATUS) {
      return data;
    }
    if (data === LOADING_STATUS) {
      let awaitTime = 0;
      while (cachedData.current[uri] === LOADING_STATUS) {
        // TODO: think about some better solution than periodically check for loading status..
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) =>
          setTimeout(resolve, LOAD_AWAIT_INTERVAL)
        );
        awaitTime += LOAD_AWAIT_INTERVAL;
        if (awaitTime >= LOAD_AWAIT_TIMEOUT) {
          return uri;
        }
      }
      return cachedData.current[uri] === FAILED_STATUS
        ? uri
        : cachedData.current[uri];
    }
    try {
      cachedData.current[uri] = LOADING_STATUS;
      // if we want label for gov attribute definition
      if (uri.startsWith(GOV_DATA_PREFIX)) {
        const fetchedData = await fetchGovData(uri);
        if (fetchedData !== uri) {
          cachedData.current[uri] = fetchedData;
          return fetchedData;
        }
      }
      // if we want label for gov "organization" with specified gov id
      if (uri.startsWith(GOV_ID_PREFIX)) {
        const fetchedData = await fetchGovData(
          uri,
          KnowledgeBaseGovAttributes.TITLE,
          null
        );
        if (fetchedData !== uri) {
          cachedData.current[uri] = fetchedData;
          return fetchedData;
        }
      }
      // TODO: add some general way of handling other types of labels
      const label = getLabelFromVocabulary(uri);
      cachedData.current[uri] = label;
      return label;
    } catch (e) {
      console.log(`error during downloading label data from uri: ${uri}`);
      console.log(e);
      cachedData.current[uri] = FAILED_STATUS;
    }
    return uri;
  };

  return (
    <VisualizationLabelsCacheContext.Provider
      value={{
        getLabel,
      }}
    >
      {children}
    </VisualizationLabelsCacheContext.Provider>
  );
}

VisualizationLabelsCacheContextProvider.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  defaultCache: PropTypes.object,
};

VisualizationLabelsCacheContextProvider.defaultProps = {
  children: null,
  defaultCache: {},
};

export { VisualizationLabelsCacheContextProvider };
export default VisualizationLabelsCacheContext;

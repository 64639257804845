import PropTypes from "prop-types";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  usePodFromProfile,
  LINKED_SERVICES_PATH,
  useWebSocket,
  useEnvConfig,
} from "mou-common";
import useServiceLinks from "../../hooks/useServiceLinks";

export const defaultServicesListContext = {
  isLoading: false,
  linkedServices: [],
  getLinkedServiceInfo: (serviceId) => {},
  refetch: () => {},
  refetchWithPromise: () => {},
  defaultServicesNeedsLinking: true,
};

const LinkedServicesContext = createContext(defaultServicesListContext);

function LinkedServicesContextProvider({ children }) {
  const [linkedServices, setLinkedServices] = useState([]);
  const [fetchStarted, setFetchStarted] = useState(false);

  const { fetchLinkedServices, isLoading } = useServiceLinks();
  const { pod } = usePodFromProfile();
  const { getDefaultServicesIds } = useEnvConfig();

  const getLinkedServiceInfo = (serviceId) => {
    return (linkedServices || []).find((ls) => ls.serviceId === serviceId);
  };

  const getLinkedServices = useCallback(async () => {
    try {
      const lnkdServices = await fetchLinkedServices();
      setLinkedServices(lnkdServices || []);
    } catch (e) {
      console.error("Getting of linked services failed: ", e);
    }
  }, [fetchLinkedServices]);

  useEffect(() => {
    // also check if POD is ready because otherwise there are some weird errors
    // coming from inner fetchLinkedServices call (pod is null) when 'fetchStarted'
    // flag is used to prevent multiple linkedServiceInfo calls
    if (pod && !fetchStarted) {
      setFetchStarted(true);
      getLinkedServices();
    }
  }, [fetchStarted, getLinkedServices, pod]);

  const refetch = () => setFetchStarted(false);

  const refetchWithPromise = async () => {
    await getLinkedServices();
  };

  const defaultServicesNeedsLinking = useMemo(() => {
    const defaultServicesIds = getDefaultServicesIds();
    // default services needs linking if at least one of services
    const needsLinking = defaultServicesIds.some((servId) => {
      const isLinked = (linkedServices || []).some(
        (serv) =>
          serv.serviceId === servId && (serv.accessGrants || []).length > 0
      );
      return !isLinked;
    });
    return needsLinking;
  }, [getDefaultServicesIds, linkedServices]);

  // every time linked services document is changed, refetch linked services
  useWebSocket(refetch, LINKED_SERVICES_PATH);
  // TODO: listen for updates of linked_services in delegation POD when in delegation mode?

  return (
    <LinkedServicesContext.Provider
      value={{
        isLoading,
        linkedServices: linkedServices || [],
        getLinkedServiceInfo,
        refetch,
        refetchWithPromise,
        defaultServicesNeedsLinking,
      }}
    >
      {children}
    </LinkedServicesContext.Provider>
  );
}

LinkedServicesContextProvider.propTypes = {
  children: PropTypes.node,
};

LinkedServicesContextProvider.defaultProps = {
  children: null,
};

export { LinkedServicesContextProvider };
export default LinkedServicesContext;

/**
 * Generated by the Inrupt Artifact Generator [@inrupt/artifact-generator], version [3.1.1]
 * as part of artifact: [mou-vocab-ext], version: [0.1.0]
 * on 'Tuesday, January 30, 2024 11:07 AM'.
 *
 * Vocabulary built from vocab list file: [mou-vocab-ext.yml].
 * The generator detected the following terms in the source vocabulary:
 *  - Classes: [0]
 *  - Properties: [5]
 *  - Literals: [0]
 *  - Constant IRIs: [0]
 *  - Constant strings: [0]
 *
 * Slovak national extension adding multilingual meta-data for Person terms used in MOU project
 *
 * Inrupt Best Practice Compliance Report:
 *  - BP-0: PASS-01 - Vocabulary IRI [https://data.gov.sk/def/ontology/person-mou-ext/] is different from the namespace IRI [http://www.w3.org/ns/person#] (and there was no need to provide overrides for either).
 *  - BP-1: All [5] terms have 'rdfs:isDefinedBy' triples.
 *  - BP-2: All [5] terms that have 'rdfs:isDefinedBy' triples (of the [5] total terms) are defined by the vocabulary IRI of [https://data.gov.sk/def/ontology/person-mou-ext/].
 */

// We prefix our local variables with underscores to (hopefully!) prevent
// potential names clashes with terms from vocabularies.
const { VocabTerm: _VocabTerm, getLocalStore } = require("@inrupt/solid-common-vocab");
const _DataFactory = require("@rdfjs/data-model");

const { namedNode: _namedNode } = _DataFactory;

function _NS(localName) {
  return _namedNode(`http://www.w3.org/ns/person#${localName}`);
}

/**
 * Slovak national extension adding multilingual meta-data for Person terms used in MOU project
 *
 * Namespace IRI: [http://www.w3.org/ns/person#]
 */
const PERSON = {
  PREFIX: "person",
  NAMESPACE: "http://www.w3.org/ns/person#",
  PREFIX_AND_NAMESPACE: { "person": "http://www.w3.org/ns/person#" },
  NS: _NS,

  // *******************
  // All the Properties.
  // *******************

  /**
   * All data associated with an individual is subject to change. Names can change for a variety of reasons, either formally or informally, and new information may come to light that means that a correction or clarification can be made to an existing record. Birth names tend to be persistent however and for this reason they are recorded by some public sector information systems. There is no granularity for birth name - the full name should be recorded in a single field.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/person-mou-ext/, http://www.w3.org/ns/person#
   */
  birthName: new _VocabTerm(
    _NS("birthName"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addType(_namedNode("http://www.w3.org/2002/07/owl#DatatypeProperty"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/person-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://www.w3.org/ns/person#"))
    .addLabel(`birth name`, "en")
    .addLabel(`rodné priezvisko`, "sk")
    .addComment(`Obsahuje rodné priezvisko, ktorým môže byť dedená časť mena fyzickej osoby.`, "sk")
    .addComment(`All data associated with an individual is subject to change. Names can change for a variety of reasons, either formally or informally, and new information may come to light that means that a correction or clarification can be made to an existing record. Birth names tend to be persistent however and for this reason they are recorded by some public sector information systems. There is no granularity for birth name - the full name should be recorded in a single field.`, "en"),

  /**
   * A person's place of birth.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/person-mou-ext/, http://www.w3.org/ns/person
   */
  placeOfBirth: new _VocabTerm(
    _NS("placeOfBirth"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addType(_namedNode("http://www.w3.org/2002/07/owl#ObjectProperty"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/person-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://www.w3.org/ns/person"))
    .addLabel(`place of birth`, "en")
    .addLabel(`miesto narodenia`, "sk")
    .addComment(`Miesto narodenia osoby.`, "sk")
    .addComment(`A person's place of birth.`, "en"),

  /**
   * A person's place of death.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/person-mou-ext/, http://www.w3.org/ns/person
   */
  placeOfDeath: new _VocabTerm(
    _NS("placeOfDeath"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addType(_namedNode("http://www.w3.org/2002/07/owl#ObjectProperty"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/person-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://www.w3.org/ns/person"))
    .addLabel(`place of death`, "en")
    .addLabel(`miesto smrti`, "sk")
    .addComment(`Miesto smrti osoby`, "sk")
    .addComment(`A person's place of death.`, "en"),

  /**
   * Residency typically provides an individual with a subset of the rights of a citizen.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/person-mou-ext/, http://www.w3.org/ns/person
   */
  residency: new _VocabTerm(
    _NS("residency"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addType(_namedNode("http://www.w3.org/2002/07/owl#ObjectProperty"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/person-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://www.w3.org/ns/person"))
    .addLabel(`residency`, "en")
    .addLabel(`bydlisko`, "sk")
    .addComment(`Miesto pobytu osoby napr. trvalé bydlisko.`, "sk")
    .addComment(`Residency typically provides an individual with a subset of the rights of a citizen.`, "en"),

  /**
   * The citizenship relationship links a Person to a Jurisdiction that has conferred citizenship rights on the individual such as the right to vote, to receive certain protection from the community or the issuance of a passport. Multiple citizenships are recorded as multiple instances of the citizenship relationship.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/person-mou-ext/, http://www.w3.org/ns/person
   */
  citizenship: new _VocabTerm(
    _NS("citizenship"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addType(_namedNode("http://www.w3.org/2002/07/owl#ObjectProperty"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/person-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://www.w3.org/ns/person"))
    .addLabel(`citizenship`, "en")
    .addLabel(`občianstvo`, "sk")
    .addComment(`Vzťah spája osobu s jurisdikciou, ktorá jednotlivcovi udelila občianske práva, ako je právo voliť, získať určitú ochranu od komunity alebo vydanie cestovného pasu. Viacnásobné občianstvá sa zaznamenávajú ako viaceré prípady občianskeho vzťahu.`, "sk")
    .addComment(`The citizenship relationship links a Person to a Jurisdiction that has conferred citizenship rights on the individual such as the right to vote, to receive certain protection from the community or the issuance of a passport. Multiple citizenships are recorded as multiple instances of the citizenship relationship.`, "en"),

}

module.exports = PERSON;

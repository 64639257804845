import T from "prop-types";
import React, { createContext, useState } from "react";

export const defaultStaticPageDialogContext = {
  open: false,
  setOpen: () => { },
  staticPageId: null,
  setStaticPageId: () => { },
  closeDialog: () => { },
};
const StaticPageDialogContext = createContext(defaultStaticPageDialogContext);

export default StaticPageDialogContext;

function StaticPageDialogProvider({ children }) {
  const [open, setOpen] = useState(null);
  const [staticPageId, setStaticPageId] = useState(null);

  const closeDialog = () => {
    setOpen(null);
  };

  return (
    <StaticPageDialogContext.Provider
      value={{
        open,
        setOpen,
        staticPageId,
        setStaticPageId,
        closeDialog,
      }}
    >
      {children}
    </StaticPageDialogContext.Provider>
  );
}

StaticPageDialogProvider.propTypes = {
  children: T.node,
};

StaticPageDialogProvider.defaultProps = {
  children: null,
};

export { StaticPageDialogProvider };

/**
 * Generated by the artifact generator [@inrupt/artifact-generator], version [2.0.0]
 * as part of artifact: [mou-vocab-ext], version: [0.1.0]
 * on 'Thursday, November 2, 2023 2:59 PM'.
 *
 * Vocabulary built from vocab list file: [mou-vocab-ext.yml].
 * The generator detected the following terms in the source vocabulary:
 *  - Classes: [1]
 *  - Properties: [8]
 *  - Literals: [0]
 *  - Constant IRIs: [0]
 *  - Constant strings: [0]
 *
 * Friend of a Friend (FOAF) vocabulary
 */

// We prefix our local variables with underscores to (hopefully!) prevent
// potential names clashes with terms from vocabularies.
const { VocabTerm: _VocabTerm, getLocalStore } = require("@inrupt/solid-common-vocab");
const _DataFactory = require("@rdfjs/data-model");

const { namedNode: _namedNode } = _DataFactory;

function _NS(localName) {
  return _namedNode(`http://xmlns.com/foaf/${localName}`);
}

/**
 * Friend of a Friend (FOAF) vocabulary
 */
const FOAF = {
  PREFIX: "foaf",
  NAMESPACE: "http://xmlns.com/foaf/",
  PREFIX_AND_NAMESPACE: { "foaf": "http://xmlns.com/foaf/" },
  NS: _NS,

  // *****************
  // All the Classes.
  // *****************

  /**
   * A personal profile RDF document.
   *
   * This term has [2] labels and comments, in the languages [NoLocale, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/foaf-mou-ext/
   */
  PersonalProfileDocument: new _VocabTerm(
    _NS("PersonalProfileDocument"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/foaf-mou-ext/"))
    .addLabelNoLanguage(`PersonalProfileDocument`)
    .addLabel(`osobný profil`, "sk")
    .addComment(`Dokument s osobným profilom.`, "sk")
    .addCommentNoLanguage(`A personal profile RDF document.`),


  // *******************
  // All the Properties.
  // *******************

  /**
   * A name for some thing.
   *
   * This term has [2] labels and comments, in the languages [NoLocale, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/foaf-mou-ext/
   */
  name: new _VocabTerm(
    _NS("name"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/foaf-mou-ext/"))
    .addLabelNoLanguage(`name`)
    .addLabel(`meno`, "sk")
    .addComment(`Meno pre nejakú vec.`, "sk")
    .addCommentNoLanguage(`A name for some thing.`),

  /**
   * The first name of a person.
   *
   * This term has [2] labels and comments, in the languages [NoLocale, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/foaf-mou-ext/
   */
  firstName: new _VocabTerm(
    _NS("firstName"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/foaf-mou-ext/"))
    .addLabelNoLanguage(`firstName`)
    .addLabel(`prvé meno`, "sk")
    .addComment(`Prvé meno osoby.`, "sk")
    .addCommentNoLanguage(`The first name of a person.`),

  /**
   * The last name of a person.
   *
   * This term has [2] labels and comments, in the languages [NoLocale, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/foaf-mou-ext/
   */
  lastName: new _VocabTerm(
    _NS("lastName"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/foaf-mou-ext/"))
    .addLabelNoLanguage(`lastName`)
    .addLabel(`priezvisko`, "sk")
    .addComment(`Posledné meno osoby.`, "sk")
    .addCommentNoLanguage(`The last name of a person.`),

  /**
   * The given name of some person.
   *
   * This term has [2] labels and comments, in the languages [NoLocale, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/foaf-mou-ext/
   */
  givenName: new _VocabTerm(
    _NS("givenName"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/foaf-mou-ext/"))
    .addLabelNoLanguage(`Given name`)
    .addLabel(`meno`, "sk")
    .addComment(`Obsahuje rodné meno, ktorým je nededená časť mena fyzickej osoby. Známe aj ako prvé meno fyzickej osoby alebo v užšom význame krstné meno fyzickej osoby.`, "sk")
    .addCommentNoLanguage(`The given name of some person.`),

  /**
   * The family name of some person.
   *
   * This term has [2] labels and comments, in the languages [NoLocale, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/foaf-mou-ext/
   */
  familyName: new _VocabTerm(
    _NS("familyName"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/foaf-mou-ext/"))
    .addLabelNoLanguage(`familyName`)
    .addLabel(`priezvisko`, "sk")
    .addComment(`Obsahuje priezvisko osoby. Posledné meno na identifikáciu členov rodiny.`, "sk")
    .addCommentNoLanguage(`The family name of some person.`),

  /**
   * The primary topic of some page or document.
   *
   * This term has [2] labels and comments, in the languages [NoLocale, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/foaf-mou-ext/
   */
  primaryTopic: new _VocabTerm(
    _NS("primaryTopic"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/foaf-mou-ext/"))
    .addLabelNoLanguage(`primary topic`)
    .addLabel(`primárna téma`, "sk")
    .addComment(`Primárna téma nejakej stránky alebo dokumentu.`, "sk")
    .addCommentNoLanguage(`The primary topic of some page or document.`),

  /**
   * A phone,  specified using fully qualified tel: URI scheme (refs: http://www.w3.org/Addressing/schemes.html#tel).
   *
   * This term has [2] labels and comments, in the languages [NoLocale, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/foaf-mou-ext/
   */
  phone: new _VocabTerm(
    _NS("phone"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/foaf-mou-ext/"))
    .addLabelNoLanguage(`phone`)
    .addLabel(`telefón`, "sk")
    .addComment(`Telefónne číslo, malo by používať tel: URI schému.`, "sk")
    .addCommentNoLanguage(`A phone,  specified using fully qualified tel: URI scheme (refs: http://www.w3.org/Addressing/schemes.html#tel).`),

  /**
   * A  personal mailbox, ie. an Internet mailbox associated with exactly one owner, the first owner of this mailbox. This is a 'static inverse functional property', in that  there is (across time and change) at most one individual that ever has any particular value for foaf:mbox.
   *
   * This term has [2] labels and comments, in the languages [NoLocale, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/foaf-mou-ext/
   */
  mbox: new _VocabTerm(
    _NS("mbox"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/foaf-mou-ext/"))
    .addLabelNoLanguage(`personal mailbox`)
    .addLabel(`osobná poštová schránka / email`, "sk")
    .addComment(`Osobná schránka, tj. internetová schránka priradená presne jednému vlastníkovi.`, "sk")
    .addCommentNoLanguage(`A  personal mailbox, ie. an Internet mailbox associated with exactly one owner, the first owner of this mailbox. This is a 'static inverse functional property', in that  there is (across time and change) at most one individual that ever has any particular value for foaf:mbox.`),

}

module.exports = FOAF;

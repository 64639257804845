import T from "prop-types";
import React, { createContext, useState } from "react";

export const defaultNotificationSettingsDialogContext = {
  open: false,
  setOpen: () => {},
  closeDialog: () => {},
};
const NotificationSettingsDialogContext = createContext(
  defaultNotificationSettingsDialogContext
);

export default NotificationSettingsDialogContext;

function NotificationSettingsDialogProvider({ children }) {
  const [open, setOpen] = useState(null);

  const closeDialog = () => {
    setOpen(null);
  };

  return (
    <NotificationSettingsDialogContext.Provider
      value={{
        open,
        setOpen,
        closeDialog,
      }}
    >
      {children}
    </NotificationSettingsDialogContext.Provider>
  );
}

NotificationSettingsDialogProvider.propTypes = {
  children: T.node,
};

NotificationSettingsDialogProvider.defaultProps = {
  children: null,
};

export { NotificationSettingsDialogProvider };

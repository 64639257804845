/**
 * Generated by the Inrupt Artifact Generator [@inrupt/artifact-generator], version [3.1.1]
 * as part of artifact: [mou-vocab-ext], version: [0.1.0]
 * on 'Tuesday, January 30, 2024 11:07 AM'.
 *
 * Vocabulary built from vocab list file: [mou-vocab-ext.yml].
 * The generator detected the following terms in the source vocabulary:
 *  - Classes: [0]
 *  - Properties: [10]
 *  - Literals: [0]
 *  - Constant IRIs: [0]
 *  - Constant strings: [0]
 *
 * Compote extension adding multilingual meta-data for Dublin Core Terms used in MOU project
 *
 * Inrupt Best Practice Compliance Report:
 *  - BP-0: PASS-01 - Vocabulary IRI [https://data.gov.sk/def/ontology/dcterms-mou-ext/] is different from the namespace IRI [http://purl.org/dc/terms/] (and there was no need to provide overrides for either).
 *  - BP-1: All [10] terms have 'rdfs:isDefinedBy' triples.
 *  - BP-2: All [10] terms that have 'rdfs:isDefinedBy' triples (of the [10] total terms) are defined by the vocabulary IRI of [https://data.gov.sk/def/ontology/dcterms-mou-ext/].
 */

// We prefix our local variables with underscores to (hopefully!) prevent
// potential names clashes with terms from vocabularies.
const { VocabTerm: _VocabTerm, getLocalStore } = require("@inrupt/solid-common-vocab");
const _DataFactory = require("@rdfjs/data-model");

const { namedNode: _namedNode } = _DataFactory;

function _NS(localName) {
  return _namedNode(`http://purl.org/dc/terms/${localName}`);
}

/**
 * Compote extension adding multilingual meta-data for Dublin Core Terms used in MOU project
 *
 * Namespace IRI: [http://purl.org/dc/terms/]
 */
const DCTERMS = {
  PREFIX: "dcterms",
  NAMESPACE: "http://purl.org/dc/terms/",
  PREFIX_AND_NAMESPACE: { "dcterms": "http://purl.org/dc/terms/" },
  NS: _NS,

  // *******************
  // All the Properties.
  // *******************

  /**
   * An entity responsible for making the resource.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/dcterms-mou-ext/, http://purl.org/dc/terms/
   */
  creator: new _VocabTerm(
    _NS("creator"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/dcterms-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://purl.org/dc/terms/"))
    .addLabel(`Creator`, "en")
    .addLabel(`tvorca`, "sk")
    .addComment(`Subjekt zodpovedný za vytvorenie zdroja.`, "sk")
    .addComment(`An entity responsible for making the resource.`, "en"),

  /**
   * Date of formal issuance of the resource.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/dcterms-mou-ext/, http://purl.org/dc/terms/
   */
  issued: new _VocabTerm(
    _NS("issued"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/dcterms-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://purl.org/dc/terms/"))
    .addLabel(`Date Issued`, "en")
    .addLabel(`dátum vydania`, "sk")
    .addComment(`Formálny dátum vydania zdroja.`, "sk")
    .addComment(`Date of formal issuance of the resource.`, "en"),

  /**
   * A name given to the resource.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/dcterms-mou-ext/, http://purl.org/dc/terms/
   */
  title: new _VocabTerm(
    _NS("title"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/dcterms-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://purl.org/dc/terms/"))
    .addLabel(`Title`, "en")
    .addLabel(`názov`, "sk")
    .addComment(`Názov zdroja.`, "sk")
    .addComment(`A name given to the resource.`, "en"),

  /**
   * The nature or genre of the resource.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/dcterms-mou-ext/, http://purl.org/dc/terms/
   */
  type: new _VocabTerm(
    _NS("type"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/dcterms-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://purl.org/dc/terms/"))
    .addLabel(`Type`, "en")
    .addLabel(`typ`, "sk")
    .addComment(`Povaha alebo žáner zdroja.`, "sk")
    .addComment(`The nature or genre of the resource.`, "en"),

  /**
   * Date (often a range) of validity of a resource.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/dcterms-mou-ext/, http://purl.org/dc/terms/
   */
  valid: new _VocabTerm(
    _NS("valid"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/dcterms-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://purl.org/dc/terms/"))
    .addLabel(`Date Valid`, "en")
    .addLabel(`dátum platnosti`, "sk")
    .addComment(`Formálny dátum platnosti zdroja.`, "sk")
    .addComment(`Date (often a range) of validity of a resource.`, "en"),

  /**
   * Date of submission of the resource.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/dcterms-mou-ext/, http://purl.org/dc/terms/
   */
  dateSubmitted: new _VocabTerm(
    _NS("dateSubmitted"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/dcterms-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://purl.org/dc/terms/"))
    .addLabel(`Date Submitted`, "en")
    .addLabel(`dátum podania`, "sk")
    .addComment(`Dátum podania zdroja.`, "sk")
    .addComment(`Date of submission of the resource.`, "en"),

  /**
   * An entity responsible for making the resource available.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/dcterms-mou-ext/, http://purl.org/dc/terms/
   */
  publisher: new _VocabTerm(
    _NS("publisher"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/dcterms-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://purl.org/dc/terms/"))
    .addLabel(`Publisher`, "en")
    .addLabel(`vydavateľ`, "sk")
    .addComment(`Subjekt zodpovedný za sprístupnenie zdroja.`, "sk")
    .addComment(`An entity responsible for making the resource available.`, "en"),

  /**
   * A topic of the resource.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/dcterms-mou-ext/, http://purl.org/dc/terms/
   */
  subject: new _VocabTerm(
    _NS("subject"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/dcterms-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://purl.org/dc/terms/"))
    .addLabel(`Subject`, "en")
    .addLabel(`predmet`, "sk")
    .addComment(`Predmet zdroja.`, "sk")
    .addComment(`A topic of the resource.`, "en"),

  /**
   * Temporal characteristics of the resource.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/dcterms-mou-ext/, http://purl.org/dc/terms/
   */
  temporal: new _VocabTerm(
    _NS("temporal"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/dcterms-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://purl.org/dc/terms/"))
    .addLabel(`Temporal Coverage`, "en")
    .addLabel(`časové rozmedzie`, "sk")
    .addComment(`Časové charakteristiky zdroja.`, "sk")
    .addComment(`Temporal characteristics of the resource.`, "en"),

  /**
   * Spatial characteristics of the resource.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/dcterms-mou-ext/, http://purl.org/dc/terms/
   */
  spatial: new _VocabTerm(
    _NS("spatial"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/dcterms-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://purl.org/dc/terms/"))
    .addLabel(`Spatial Coverage`, "en")
    .addLabel(`priestorové pokrytie`, "sk")
    .addComment(`Priestorové charakteristiky zdroja.`, "sk")
    .addComment(`Spatial characteristics of the resource.`, "en"),

}

module.exports = DCTERMS;

/**
 * Generated by the Inrupt Artifact Generator [@inrupt/artifact-generator], version [3.1.1]
 * as part of artifact: [mou-vocab-ext], version: [0.1.0]
 * on 'Tuesday, January 30, 2024 11:07 AM'.
 *
 * Vocabulary built from vocab list file: [mou-vocab-ext.yml].
 * The generator detected the following terms in the source vocabulary:
 *  - Classes: [0]
 *  - Properties: [4]
 *  - Literals: [0]
 *  - Constant IRIs: [0]
 *  - Constant strings: [0]
 *
 * Slovak national extension adding terms used in MOU currently not present in Znalosti portal
 *
 * Inrupt Best Practice Compliance Report:
 *  - BP-0: PASS-01 - Vocabulary IRI [https://data.gov.sk/def/ontology/transport-mou-ext/] is different from the namespace IRI [https://data.gov.sk/def/ontology/transport/] (and there was no need to provide overrides for either).
 *  - BP-1: All [4] terms have 'rdfs:isDefinedBy' triples.
 *  - BP-2: All [4] terms that have 'rdfs:isDefinedBy' triples (of the [4] total terms) are defined by the vocabulary IRI of [https://data.gov.sk/def/ontology/transport-mou-ext/].
 */

// We prefix our local variables with underscores to (hopefully!) prevent
// potential names clashes with terms from vocabularies.
const { VocabTerm: _VocabTerm, getLocalStore } = require("@inrupt/solid-common-vocab");
const _DataFactory = require("@rdfjs/data-model");

const { namedNode: _namedNode } = _DataFactory;

function _NS(localName) {
  return _namedNode(`https://data.gov.sk/def/ontology/transport/${localName}`);
}

/**
 * Slovak national extension adding terms used in MOU currently not present in Znalosti portal
 *
 * Namespace IRI: [https://data.gov.sk/def/ontology/transport/]
 */
const TPT = {
  PREFIX: "tpt",
  NAMESPACE: "https://data.gov.sk/def/ontology/transport/",
  PREFIX_AND_NAMESPACE: { "tpt": "https://data.gov.sk/def/ontology/transport/" },
  NS: _NS,

  // *******************
  // All the Properties.
  // *******************

  /**
   * Druh preukazu/osvedčenia
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/transport-mou-ext/
   */
  licenseKind: new _VocabTerm(
    _NS("licenseKind"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/transport-mou-ext/"))
    .addLabel(`druh preukazu/osvedčenia`, "sk")
    .addComment(`Druh preukazu/osvedčenia`, "sk"),

  /**
   * Druh povolenia (napr. certifikovanej organizácie).
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/transport-mou-ext/
   */
  permissionCategory: new _VocabTerm(
    _NS("permissionCategory"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/transport-mou-ext/"))
    .addLabel(`druh povolenia`, "sk")
    .addComment(`Druh povolenia (napr. certifikovanej organizácie).`, "sk"),

  /**
   * Typ personálu
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/transport-mou-ext/
   */
  professionType: new _VocabTerm(
    _NS("professionType"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/transport-mou-ext/"))
    .addLabel(`typ personálu`, "sk")
    .addComment(`Typ personálu`, "sk"),

  /**
   * Oblasť dopravy
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/transport-mou-ext/
   */
  transportArea: new _VocabTerm(
    _NS("transportArea"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/transport-mou-ext/"))
    .addLabel(`oblasť dopravy`, "sk")
    .addComment(`Oblasť dopravy`, "sk"),

}

module.exports = TPT;

/**
 * Generated by the Inrupt Artifact Generator [@inrupt/artifact-generator], version [3.1.1]
 * as part of artifact: [mou-vocab-ext], version: [0.1.0]
 * on 'Tuesday, January 30, 2024 11:07 AM'.
 *
 * Vocabulary built from vocab list file: [mou-vocab-ext.yml].
 * The generator detected the following terms in the source vocabulary:
 *  - Classes: [0]
 *  - Properties: [1]
 *  - Literals: [0]
 *  - Constant IRIs: [0]
 *  - Constant strings: [0]
 *
 * Slovak national extension adding multilingual meta-data for PROV terms used in MOU project
 *
 * Inrupt Best Practice Compliance Report:
 *  - BP-0: PASS-01 - Vocabulary IRI [https://data.gov.sk/def/ontology/prov-mou-ext/] is different from the namespace IRI [http://www.w3.org/ns/prov#] (and there was no need to provide overrides for either).
 *  - BP-1: All [1] terms have 'rdfs:isDefinedBy' triples.
 *  - BP-2: All [1] terms that have 'rdfs:isDefinedBy' triples (of the [1] total terms) are defined by the vocabulary IRI of [https://data.gov.sk/def/ontology/prov-mou-ext/].
 */

// We prefix our local variables with underscores to (hopefully!) prevent
// potential names clashes with terms from vocabularies.
const { VocabTerm: _VocabTerm, getLocalStore } = require("@inrupt/solid-common-vocab");
const _DataFactory = require("@rdfjs/data-model");

const { namedNode: _namedNode } = _DataFactory;

function _NS(localName) {
  return _namedNode(`http://www.w3.org/ns/prov#${localName}`);
}

/**
 * Slovak national extension adding multilingual meta-data for PROV terms used in MOU project
 *
 * Namespace IRI: [http://www.w3.org/ns/prov#]
 */
const PROV = {
  PREFIX: "prov",
  NAMESPACE: "http://www.w3.org/ns/prov#",
  PREFIX_AND_NAMESPACE: { "prov": "http://www.w3.org/ns/prov#" },
  NS: _NS,

  // *******************
  // All the Properties.
  // *******************

  /**
   * The _optional_ Role that an Entity assumed in the context of an Activity. For example, :baking prov:used :spoon; prov:qualified [ a prov:Usage; prov:entity :spoon; prov:hadRole roles:mixing_implement ].
   *
   * This term provides multilingual descriptions, with [2] labels in languages [NoLocale, sk], but [3] comments in languages [NoLocale, en, sk] (so the difference is only between English and NoLocale, which we consider the same).
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/prov-mou-ext/, http://www.w3.org/ns/prov-o#
   */
  hadRole: new _VocabTerm(
    _NS("hadRole"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/2002/07/owl#ObjectProperty"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/prov-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://www.w3.org/ns/prov-o#"))
    .addLabelNoLanguage(`hadRole`)
    .addLabel(`mal rolu`, "sk")
    .addComment(`Nepovinná rola, ktorú Entita prevzala v kontexte aktivity.`, "sk")
    .addCommentNoLanguage(`This property has multiple RDFS domains to suit multiple OWL Profiles. See <a href="#owl-profile">PROV-O OWL Profile</a>.`)
    .addComment(`The _optional_ Role that an Entity assumed in the context of an Activity. For example, :baking prov:used :spoon; prov:qualified [ a prov:Usage; prov:entity :spoon; prov:hadRole roles:mixing_implement ].`, "en"),

}

module.exports = PROV;

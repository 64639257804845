/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material";
import {
  SessionProvider,
  ConfigurationsContextProvider,
  EnvConfigContextProvider,
  InformationSourcesContextProvider,
  InitializationContextProvider,
  JsonLdCacheContextProvider,
  ServicesListContextProvider,
  UsageLimitTranslationsContextProvider,
  AlertProvider,
  AccountInfoContextProvider,
  DelegationContextProvider,
  ViewedMessagesContextProvider,
  WebSocketContextProvider,
  AllMessagesContextProvider,
  NativeWrapperContextProvider,
  DatasetsListContextProvider,
} from "mou-common";
import CssBaseline from "@mui/material/CssBaseline";
import "core-js";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/sk";
import Head from "next/head";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { initReactI18next, useTranslation } from "react-i18next";
import Script from "next/script";
import Notification from "../components/notification";
import skTranslations from "../constants/translations/sk";
import { CancelAccountDialogProvider } from "../src/contexts/cancelAccountDialogContext";
import { CleanupPodDialogProvider } from "../src/contexts/cleanupPodDialogContext";
import {
  DatasetFilterContextProvider,
  NotificationsFilterContextProvider,
} from "../src/contexts/dataFilterContext";
import { SubmenuContextProvider } from "../src/contexts/subMenuContext";
import { LinkedServicesContextProvider } from "../src/contexts/linkedServicesContext";
import { NotificationSettingsDialogProvider } from "../src/contexts/notificationSettingsDialogContext";
import { StaticPageDialogProvider } from "../src/contexts/staticPageDialogContext";
import { VisualizationLabelsCacheContextProvider } from "../src/contexts/visualizationLabelsCacheContext";
import { ConsentsManagementDialogProvider } from "../src/contexts/consentsManagementDialogContext";
import theme from "../src/theme";
import "./styles.css";
import InboxMessagesWssProcessor from "../components/inboxMessagesWssProcessor";
import { NotificationsDialogProvider } from "../src/contexts/notificationsDialogContext";
import { ProfileDialogProvider } from "../src/contexts/profileDialogContext";
import { DelegationsManagementDialogProvider } from "../src/contexts/delegationsManagementDialogContext";
import { PersonalPreferencesDialogProvider } from "../src/contexts/personalPreferencesDialogContext";
import { DevImageErrorDialogProvider } from "../src/contexts/devImageErrorDialogContext";

i18next.use(initReactI18next).init({
  resources: {
    sk: skTranslations,
  },
  lng: "sk",
  fallbackLng: "sk",
  interpolation: {
    escapeValue: false,
  },
});
moment.locale("sk");

export function hasSolidAuthClientHash() {
  if (typeof window === "undefined") {
    return false;
  }

  if (window.location.hash.indexOf("#access_token=") === 0) {
    return true;
  }

  return false;
}

export default function App(props) {
  const { t } = useTranslation();
  const { Component, pageProps } = props;
  const router = useRouter();
  const [history] = useState([]);

  useEffect(() => {
    // Remove injected serverside JSS
    const jssStyles = document.querySelector("#jss-server-side");

    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        <title>MOU Client</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <Script src="/n3-1.16.3.min.js" strategy="lazyOnload" />
      <EnvConfigContextProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <SessionProvider
              sessionId="pod-browser"
              restorePreviousSession
              onSessionRestore={() => router.push("/")}
            >
              <NativeWrapperContextProvider onBackPressed={() => router.back()}>
                <AccountInfoContextProvider>
                  <WebSocketContextProvider>
                    <AlertProvider>
                      <InitializationContextProvider>
                        <ConfigurationsContextProvider>
                          <JsonLdCacheContextProvider>
                            <UsageLimitTranslationsContextProvider
                              translateFunction={t}
                            >
                              <StaticPageDialogProvider>
                                <NotificationSettingsDialogProvider>
                                  <DelegationContextProvider>
                                    <ServicesListContextProvider>
                                      <DatasetsListContextProvider
                                        translateFunction={t}
                                        searchInServiceData
                                      >
                                        <ViewedMessagesContextProvider>
                                          <AllMessagesContextProvider>
                                            <SubmenuContextProvider>
                                              <InformationSourcesContextProvider>
                                                <InboxMessagesWssProcessor>
                                                  <VisualizationLabelsCacheContextProvider>
                                                    <NotificationsDialogProvider>
                                                      <CancelAccountDialogProvider>
                                                        <LinkedServicesContextProvider>
                                                          <ConsentsManagementDialogProvider>
                                                            <DelegationsManagementDialogProvider>
                                                              <ProfileDialogProvider>
                                                                <DatasetFilterContextProvider>
                                                                  <NotificationsFilterContextProvider>
                                                                    <CleanupPodDialogProvider>
                                                                      <DevImageErrorDialogProvider>
                                                                        <PersonalPreferencesDialogProvider>
                                                                          <CssBaseline />
                                                                          <div className="app-layout">
                                                                            <main className="app-layout__main">
                                                                              <Component
                                                                                {...pageProps}
                                                                                history={
                                                                                  history
                                                                                }
                                                                              />
                                                                            </main>
                                                                          </div>
                                                                          <Notification />
                                                                        </PersonalPreferencesDialogProvider>
                                                                      </DevImageErrorDialogProvider>
                                                                    </CleanupPodDialogProvider>
                                                                  </NotificationsFilterContextProvider>
                                                                </DatasetFilterContextProvider>
                                                              </ProfileDialogProvider>
                                                            </DelegationsManagementDialogProvider>
                                                          </ConsentsManagementDialogProvider>
                                                        </LinkedServicesContextProvider>
                                                      </CancelAccountDialogProvider>
                                                    </NotificationsDialogProvider>
                                                  </VisualizationLabelsCacheContextProvider>
                                                </InboxMessagesWssProcessor>
                                              </InformationSourcesContextProvider>
                                            </SubmenuContextProvider>
                                          </AllMessagesContextProvider>
                                        </ViewedMessagesContextProvider>
                                      </DatasetsListContextProvider>
                                    </ServicesListContextProvider>
                                  </DelegationContextProvider>
                                </NotificationSettingsDialogProvider>
                              </StaticPageDialogProvider>
                            </UsageLimitTranslationsContextProvider>
                          </JsonLdCacheContextProvider>
                        </ConfigurationsContextProvider>
                      </InitializationContextProvider>
                    </AlertProvider>
                  </WebSocketContextProvider>
                </AccountInfoContextProvider>
              </NativeWrapperContextProvider>
            </SessionProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </EnvConfigContextProvider>
    </>
  );
}

App.defaultProps = {
  pageProps: null,
};

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object,
};

import { toRDF } from "jsonld";
import { QueryEngine } from "@comunica/query-sparql-rdfjs";
import N3 from "n3";
import {
  InboxMessageToastKeywords,
  InboxMessageTypes,
  getIdsFromDataIris,
  isNullOrEmptyString,
  isString,
} from "mou-common";
import getConcernedDatasetIdsFromAccessRequest from "../access/request";

const getDatasetIdsFromMessageContent = (messageContent) => {
  const messageType = messageContent?.messageType?.["@id"];
  let datasetIds = [];
  if (messageType === InboxMessageTypes.MOU_DATA_ACCESS_REQUEST) {
    datasetIds = getConcernedDatasetIdsFromAccessRequest(
      messageContent?.payload?.accessRequest
    );
  } else {
    datasetIds = getIdsFromDataIris(messageContent?.payload?.dataSet);
  }
  // TODO: add support for more specific message types if needed (e.g. if they have different payload structure like AccessRequest does)
  if (datasetIds.length === 0) {
    console.error(
      "Couldn't extract datasets ids from inbox message content: ",
      messageContent
    );
  }
  return datasetIds;
};

const getDatasetNameFromMessageContent = (messageContent, podDatasets) => {
  const datasetIds = getDatasetIdsFromMessageContent(messageContent);
  if (datasetIds.length > 0) {
    const relatedDatasetNames = datasetIds.reduce((prevNames, currId) => {
      const dtName = podDatasets[currId]?.metadata?.name;
      if (!isNullOrEmptyString(dtName)) {
        const newNames = [...prevNames];
        newNames.push(dtName);
        return newNames;
      }
      return prevNames;
    }, []);
    const datasetNames = relatedDatasetNames.join(", ");
    if (isNullOrEmptyString(datasetNames)) {
      console.error(
        "Couldn't determine dataset names for dataset ids and podDatasets: ",
        datasetIds,
        podDatasets
      );
    }
    return datasetNames;
  }
  return "";
};

const getDatasetSourceFromMessageContent = (messageContent, podDatasets) => {
  const datasetIds = getDatasetIdsFromMessageContent(messageContent);
  if (datasetIds.length > 0) {
    // there is assumption that if multiple datasets are related to single message which is using
    // InboxMessageToastKeywords.DATA_SOURCE keyword, they are all from same data source so we
    // are showing data source of first dataset
    const dataSource =
      podDatasets[datasetIds[0]]?.metadata?.category?.label || "";
    if (isNullOrEmptyString(dataSource)) {
      console.error(
        "Couldn't determine dataSource for dataset ids and podDatasets: ",
        datasetIds,
        podDatasets
      );
    }
    return dataSource;
  }
  return "";
};

const enhanceToastText = (toastText, messageContent, podDatasets) => {
  let enhancedText = toastText || "";
  if (
    enhancedText.toUpperCase().includes(InboxMessageToastKeywords.DATASET_NAME)
  ) {
    enhancedText = enhancedText.replaceAll(
      InboxMessageToastKeywords.DATASET_NAME,
      getDatasetNameFromMessageContent(messageContent, podDatasets)
    );
  }
  if (
    enhancedText.toUpperCase().includes(InboxMessageToastKeywords.DATA_SOURCE)
  ) {
    enhancedText = enhancedText.replaceAll(
      InboxMessageToastKeywords.DATA_SOURCE,
      getDatasetSourceFromMessageContent(messageContent, podDatasets)
    );
  }
  return enhancedText;
};

const getToastTextFromMessageData = async (
  messageData,
  moucmnPrefix,
  podDatasets
) => {
  try {
    const dataObject = isString(messageData)
      ? JSON.parse(messageData)
      : messageData;
    const engine = new QueryEngine();
    const store = new N3.Store();
    const parser = new N3.Parser({ format: "N-Quads" });

    const nQuads = await toRDF(dataObject, {
      format: "application/n-quads",
    });

    // parse sync using n3.parse
    const quads = parser.parse(nQuads);
    store.addQuads(quads);

    const queryToastMessage = await engine.queryBindings(
      `
    PREFIX moucmn: <${moucmnPrefix}>
    SELECT * WHERE {
      ?data moucmn:messageType ?messageType.
      ?messageType moucmn:toastMessage ?toastMessage
      FILTER (lang(?toastMessage) = "sk")
    }
    `,
      {
        sources: [store],
      }
    );
    const messages = await queryToastMessage.toArray();
    const toastMessage = messages[0]?.get("toastMessage")?.value || "";
    const enhancedToastMessage = enhanceToastText(
      toastMessage,
      messageData,
      podDatasets
    );
    return enhancedToastMessage;
  } catch (e) {
    console.error("Error during toast message parsing: ", e);
  }
  return null;
};

// eslint-disable-next-line import/prefer-default-export
export { getToastTextFromMessageData };

import ADMS from "./GeneratedVocab/ADMS";
import CCCEV from "./GeneratedVocab/CCCEV";
import DCTERMS from "./GeneratedVocab/DCTERMS";
import EGOV from "./GeneratedVocab/EGOV";
import FIN from "./GeneratedVocab/FIN";
import FOAF from "./GeneratedVocab/FOAF";
import JUS from "./GeneratedVocab/JUS";
import LOCA from "./GeneratedVocab/LOCA";
import LSUB from "./GeneratedVocab/LSUB";
import PERSON from "./GeneratedVocab/PERSON";
import PPER from "./GeneratedVocab/PPER";
import PROV from "./GeneratedVocab/PROV";
import SCHEMA from "./GeneratedVocab/SCHEMA";
import SKOS from "./GeneratedVocab/SKOS";
import TPT from "./GeneratedVocab/TPT";

export default {
  ADMS,
  CCCEV,
  DCTERMS,
  EGOV,
  FIN,
  FOAF,
  JUS,
  LOCA,
  LSUB,
  PERSON,
  PPER,
  PROV,
  SCHEMA,
  SKOS,
  TPT,
};

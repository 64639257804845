/**
 * Generated by the Inrupt Artifact Generator [@inrupt/artifact-generator], version [3.1.1]
 * as part of artifact: [mou-vocab-ext], version: [0.1.0]
 * on 'Tuesday, January 30, 2024 11:07 AM'.
 *
 * Vocabulary built from vocab list file: [mou-vocab-ext.yml].
 * The generator detected the following terms in the source vocabulary:
 *  - Classes: [2]
 *  - Properties: [12]
 *  - Literals: [0]
 *  - Constant IRIs: [0]
 *  - Constant strings: [0]
 *
 * Compote extension adding terms used in MOU currently not present in Znalosti portal
 *
 * Inrupt Best Practice Compliance Report:
 *  - BP-0: PASS-01 - Vocabulary IRI [https://data.gov.sk/def/ontology/physical-person-mou-ext/] is different from the namespace IRI [https://data.gov.sk/def/ontology/physical-person/] (and there was no need to provide overrides for either).
 *  - BP-1: All [14] terms have 'rdfs:isDefinedBy' triples.
 *  - BP-2: All [14] terms that have 'rdfs:isDefinedBy' triples (of the [14] total terms) are defined by the vocabulary IRI of [https://data.gov.sk/def/ontology/physical-person-mou-ext/].
 */

// We prefix our local variables with underscores to (hopefully!) prevent
// potential names clashes with terms from vocabularies.
const { VocabTerm: _VocabTerm, getLocalStore } = require("@inrupt/solid-common-vocab");
const _DataFactory = require("@rdfjs/data-model");

const { namedNode: _namedNode } = _DataFactory;

function _NS(localName) {
  return _namedNode(`https://data.gov.sk/def/ontology/physical-person/${localName}`);
}

/**
 * Compote extension adding terms used in MOU currently not present in Znalosti portal
 *
 * Namespace IRI: [https://data.gov.sk/def/ontology/physical-person/]
 */
const PPER = {
  PREFIX: "pper",
  NAMESPACE: "https://data.gov.sk/def/ontology/physical-person/",
  PREFIX_AND_NAMESPACE: { "pper": "https://data.gov.sk/def/ontology/physical-person/" },
  NS: _NS,

  // *****************
  // All the Classes.
  // *****************

  /**
   * Identifikátor osoby ťažko zdravotne postihnutej.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/physical-person-mou-ext/
   */
  DisabilityCard: new _VocabTerm(
    _NS("DisabilityCard"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/2000/01/rdf-schema#Class"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/physical-person-mou-ext/"))
    .addLabel(`TZP karta`, "sk")
    .addComment(`Identifikátor osoby ťažko zdravotne postihnutej.`, "sk"),

  /**
   * Typ karty osoby ťažko zdravotne postihnutej.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/physical-person-mou-ext/
   */
  DisabilityCardType: new _VocabTerm(
    _NS("DisabilityCardType"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/2000/01/rdf-schema#Class"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/physical-person-mou-ext/"))
    .addLabel(`Typ TZP karty`, "sk")
    .addComment(`Typ karty osoby ťažko zdravotne postihnutej.`, "sk"),


  // *******************
  // All the Properties.
  // *******************

  /**
   * Rodné číslo fyzickej osoby.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/physical-person-mou-ext/
   */
  birthNumberCode: new _VocabTerm(
    _NS("birthNumberCode"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/physical-person-mou-ext/"))
    .addLabel(`rodné číslo`, "sk")
    .addComment(`Rodné číslo fyzickej osoby.`, "sk"),

  /**
   * Adresa bydliska alebo pobytu fyzickej osoby.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/physical-person-mou-ext/
   */
  residenceAddress: new _VocabTerm(
    _NS("residenceAddress"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/physical-person-mou-ext/"))
    .addLabel(`adresa bydliska`, "sk")
    .addComment(`Adresa bydliska alebo pobytu fyzickej osoby.`, "sk"),

  /**
   *
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/physical-person-mou-ext/
   */
  officialRecord: new _VocabTerm(
    _NS("officialRecord"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/physical-person-mou-ext/"))
    .addLabel(`oficiálny záznam`, "sk")
    .addComment(``, "sk"),

  /**
   *
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/physical-person-mou-ext/
   */
  specialRecords: new _VocabTerm(
    _NS("specialRecords"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/physical-person-mou-ext/"))
    .addLabel(`špeciálny záznam`, "sk")
    .addComment(``, "sk"),

  /**
   * Osoba pochádza z chudobného regiónu (tzv. hladové doliny).
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/physical-person-mou-ext/
   */
  isFromPoorRegion: new _VocabTerm(
    _NS("isFromPoorRegion"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/physical-person-mou-ext/"))
    .addLabel(`je z chudobného regiónu`, "sk")
    .addComment(`Osoba pochádza z chudobného regiónu (tzv. hladové doliny).`, "sk"),

  /**
   * Informácie zo sobášneho listu subjektu.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/physical-person-mou-ext/
   */
  marriageCertificate: new _VocabTerm(
    _NS("marriageCertificate"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/physical-person-mou-ext/"))
    .addLabel(`sobášny list`, "sk")
    .addComment(`Informácie zo sobášneho listu subjektu.`, "sk"),

  /**
   * Deň, mesiac a rok uzavretia manželstva.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/physical-person-mou-ext/
   */
  dateOfMarriage: new _VocabTerm(
    _NS("dateOfMarriage"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/physical-person-mou-ext/"))
    .addLabel(`dátum uzavretia manželstva`, "sk")
    .addComment(`Deň, mesiac a rok uzavretia manželstva.`, "sk"),

  /**
   * Miesto uzavretia manželstva
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/physical-person-mou-ext/
   */
  placeOfMarriage: new _VocabTerm(
    _NS("placeOfMarriage"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/physical-person-mou-ext/"))
    .addLabel(`miesto uzavretia manželstva`, "sk")
    .addComment(`Miesto uzavretia manželstva`, "sk"),

  /**
   * Časové obdobie kedy bol subjekt registrovaný ako zdravotne postihnutý.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/physical-person-mou-ext/
   */
  disabilityRegistrationPeriod: new _VocabTerm(
    _NS("disabilityRegistrationPeriod"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/physical-person-mou-ext/"))
    .addLabel(`perióda registrácie postihnutia`, "sk")
    .addComment(`Časové obdobie kedy bol subjekt registrovaný ako zdravotne postihnutý.`, "sk"),

  /**
   * Informácia, či subjekt má alebo nemá aktívne zdravotné postihnutie.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/physical-person-mou-ext/
   */
  hasActiveDisability: new _VocabTerm(
    _NS("hasActiveDisability"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/physical-person-mou-ext/"))
    .addLabel(`má zdravotné postihnutie`, "sk")
    .addComment(`Informácia, či subjekt má alebo nemá aktívne zdravotné postihnutie.`, "sk"),

  /**
   * Konkrétna hodnota titulu pred menom definovaného hodnotami číselníka CL000062 Titul pred menom.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/physical-person-mou-ext/
   */
  namePrefixType: new _VocabTerm(
    _NS("namePrefixType"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/physical-person-mou-ext/"))
    .addLabel(`typ titulu pred menom`, "sk")
    .addComment(`Konkrétna hodnota titulu pred menom definovaného hodnotami číselníka CL000062 Titul pred menom.`, "sk"),

  /**
   * Konkrétna hodnota titulu za menom definovaného hodnotami číselníka CL000063 Titul za menom.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/physical-person-mou-ext/
   */
  nameSuffixType: new _VocabTerm(
    _NS("nameSuffixType"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/physical-person-mou-ext/"))
    .addLabel(`typ titulu za menom`, "sk")
    .addComment(`Konkrétna hodnota titulu za menom definovaného hodnotami číselníka CL000063 Titul za menom.`, "sk"),

}

module.exports = PPER;

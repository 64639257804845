import T from "prop-types";
import React, { createContext, useState } from "react";

export const defaultPersonalPreferencesDialogContext = {
  open: false,
  setOpen: () => {},
  closeDialog: () => {},
};
const PersonalPreferencesDialogContext = createContext(
  defaultPersonalPreferencesDialogContext
);

export default PersonalPreferencesDialogContext;

function PersonalPreferencesDialogProvider({ children }) {
  const [open, setOpen] = useState(false);

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <PersonalPreferencesDialogContext.Provider
      value={{
        open,
        setOpen,
        closeDialog,
      }}
    >
      {children}
    </PersonalPreferencesDialogContext.Provider>
  );
}

PersonalPreferencesDialogProvider.propTypes = {
  children: T.node,
};

PersonalPreferencesDialogProvider.defaultProps = {
  children: null,
};

export { PersonalPreferencesDialogProvider };

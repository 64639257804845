import T from "prop-types";
import React, { createContext, useState } from "react";

export const defaultConsentsManagementDialogContext = {
  open: false,
  setOpen: () => {},
  closeDialog: () => {},
};
const ConsentsManagementDialogContext = createContext(
  defaultConsentsManagementDialogContext
);

export default ConsentsManagementDialogContext;

function ConsentsManagementDialogProvider({ children }) {
  const [open, setOpen] = useState(false);

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <ConsentsManagementDialogContext.Provider
      value={{
        open,
        setOpen,
        closeDialog,
      }}
    >
      {children}
    </ConsentsManagementDialogContext.Provider>
  );
}

ConsentsManagementDialogProvider.propTypes = {
  children: T.node,
};

ConsentsManagementDialogProvider.defaultProps = {
  children: null,
};

export { ConsentsManagementDialogProvider };

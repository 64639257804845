import { Slide, Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import { AlertContext } from "mou-common";
import React, { useContext } from "react";
import styles from "./styles.module.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Notification() {
  const { alertOpen, message, severity, setAlertOpen } =
    useContext(AlertContext);
  const onClose = () => setAlertOpen(false);

  return (
    <Snackbar
      open={alertOpen}
      autoHideDuration={4000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      TransitionComponent={Transition}
      classes={{
        anchorOriginBottomCenter: styles.anchorOriginBottomCenter,
      }}
      ContentProps={{
        classes: {
          root: styles.contentRoot,
        },
      }}
    >
      <Alert
        severity={severity}
        variant="filled"
        classes={{ root: styles.contentRoot, icon: styles.icon }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

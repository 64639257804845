import T from "prop-types";
import React, { createContext, useState } from "react";

export const defaultSubmenuContext = {
  open: false,
  setOpen: () => {},
  closeDialog: () => {},
};
const SubmenuContext = createContext(defaultSubmenuContext);

export default SubmenuContext;

function SubmenuContextProvider({ children }) {
  const [open, setOpen] = useState(null);

  const closeDialog = () => {
    setOpen(null);
  };

  return (
    <SubmenuContext.Provider
      value={{
        open,
        setOpen,
        closeDialog,
      }}
    >
      {children}
    </SubmenuContext.Provider>
  );
}

SubmenuContextProvider.propTypes = {
  children: T.node,
};

SubmenuContextProvider.defaultProps = {
  children: null,
};

export { SubmenuContextProvider };

/**
 * Generated by the Inrupt Artifact Generator [@inrupt/artifact-generator], version [3.1.1]
 * as part of artifact: [mou-vocab-ext], version: [0.1.0]
 * on 'Tuesday, January 30, 2024 11:07 AM'.
 *
 * Vocabulary built from vocab list file: [mou-vocab-ext.yml].
 * The generator detected the following terms in the source vocabulary:
 *  - Classes: [0]
 *  - Properties: [1]
 *  - Literals: [0]
 *  - Constant IRIs: [0]
 *  - Constant strings: [0]
 *
 * Slovak national extension adding multilingual meta-data for SKOS terms used in MOU project
 *
 * Inrupt Best Practice Compliance Report:
 *  - BP-0: PASS-01 - Vocabulary IRI [https://data.gov.sk/def/ontology/skos-mou-ext/] is different from the namespace IRI [http://www.w3.org/2004/02/skos/core#] (and there was no need to provide overrides for either).
 *  - BP-1: All [1] terms have 'rdfs:isDefinedBy' triples.
 *  - BP-2: All [1] terms that have 'rdfs:isDefinedBy' triples (of the [1] total terms) are defined by the vocabulary IRI of [https://data.gov.sk/def/ontology/skos-mou-ext/].
 */

// We prefix our local variables with underscores to (hopefully!) prevent
// potential names clashes with terms from vocabularies.
const { VocabTerm: _VocabTerm, getLocalStore } = require("@inrupt/solid-common-vocab");
const _DataFactory = require("@rdfjs/data-model");

const { namedNode: _namedNode } = _DataFactory;

function _NS(localName) {
  return _namedNode(`http://www.w3.org/2004/02/skos/core#${localName}`);
}

/**
 * Slovak national extension adding multilingual meta-data for SKOS terms used in MOU project
 *
 * Namespace IRI: [http://www.w3.org/2004/02/skos/core#]
 */
const SKOS = {
  PREFIX: "skos",
  NAMESPACE: "http://www.w3.org/2004/02/skos/core#",
  PREFIX_AND_NAMESPACE: { "skos": "http://www.w3.org/2004/02/skos/core#" },
  NS: _NS,

  // *******************
  // All the Properties.
  // *******************

  /**
   * A notation, also known as classification code, is a string of characters such as "T58.5" or "303.4833" used to uniquely identify a concept within the scope of a given concept scheme.
   *
   * This term has [2] labels and comments, in the languages [en, sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/skos-mou-ext/, http://www.w3.org/2004/02/skos/core
   */
  notation: new _VocabTerm(
    _NS("notation"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addType(_namedNode("http://www.w3.org/2002/07/owl#DatatypeProperty"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/skos-mou-ext/"))
    .addIsDefinedBy(_namedNode("http://www.w3.org/2004/02/skos/core"))
    .addLabel(`notation`, "en")
    .addLabel(`označenie`, "sk")
    .addComment(`Predstavuje reťazec znakov, ako napríklad „T58.5“ alebo „303.4833“, ktorý sa používa na jedinečnú identifikáciu konceptu v rámci danej schémy.`, "sk"),

}

module.exports = SKOS;

import { getIdsFromDataIris } from "mou-common";

/* Model functions */

export default function getConcernedDatasetIdsFromAccessRequest(accessRequest) {
  const forPersonalData =
    accessRequest?.credentialSubject?.hasConsent?.forPersonalData;
  const datasetIds = getIdsFromDataIris(forPersonalData);
  return datasetIds;
}

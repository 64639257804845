/* istanbul ignore file */
import { createTheme, adaptV4Theme } from "@mui/material/styles";
// import defaultTheme from "@solid/lit-prism-theme-sdk-default";

// export default createTheme(
//   adaptV4Theme({
//     ...defaultTheme,
//     palette: {
//       ...defaultTheme.palette,
//       primary: {
//         ...defaultTheme.palette.primary,
//         main: "#113077",
//       },
//       secondary: {
//         ...defaultTheme.palette.secondary,
//         main: "#00823B",
//       },
//     },
//   })
// );

export default createTheme({
  palette: {
    primary: {
      main: "#113077",
    },
    secondary: {
      main: "#00823B",
    },
  },
});

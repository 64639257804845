import { useMemo, useContext } from "react";
import {
  GET_INBOX_API,
  ensureTrailingSlash,
  joinPath,
  useEnvConfig,
  usePodFromProfile,
  useSession,
  AllMessagesContext,
} from "mou-common";
import fetch from "unfetch";

const indexerFetcher = (indexerUrl, resourceUrl, refreshToken) =>
  fetch(indexerUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      path: resourceUrl,
      refreshToken,
    }),
  }).then(async (r) => {
    return r.json();
  });

export default function useIndexer() {
  const { getIndexerUrl } = useEnvConfig();
  const { session } = useSession();
  const { refetchInbox } = useContext(AllMessagesContext);
  const { pod } = usePodFromProfile();

  const indexerUrl = useMemo(() => getIndexerUrl(), [getIndexerUrl]);

  const indexResource = async (resourceUrl) => {
    try {
      console.log(`Index resource: ${resourceUrl}`);
      const refreshToken = session?.info?.refreshToken;
      await indexerFetcher(indexerUrl, resourceUrl, refreshToken);
      return true;
    } catch (e) {
      console.error(`Failed to index resource ${resourceUrl}`);
      console.error(e);
    }
    return false;
  };

  const indexInboxMessage = async (messageId) => {
    const messageUrl = joinPath(pod, GET_INBOX_API, messageId);
    await indexResource(messageUrl);
  };

  const indexInbox = async (podIri) => {
    await indexResource(ensureTrailingSlash(joinPath(podIri, GET_INBOX_API)));
    console.log(`indexInbox finished`);
    refetchInbox();
  };

  return {
    isIndexerEnabled: !!indexerUrl,
    indexResource,
    indexInbox,
    indexInboxMessage,
  };
}

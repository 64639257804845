/**
 * Generated by the Inrupt Artifact Generator [@inrupt/artifact-generator], version [3.1.1]
 * as part of artifact: [mou-vocab-ext], version: [0.1.0]
 * on 'Tuesday, January 30, 2024 11:07 AM'.
 *
 * Vocabulary built from vocab list file: [mou-vocab-ext.yml].
 * The generator detected the following terms in the source vocabulary:
 *  - Classes: [0]
 *  - Properties: [1]
 *  - Literals: [0]
 *  - Constant IRIs: [0]
 *  - Constant strings: [0]
 *
 * Slovak national extension adding terms used in MOU currently not present in Znalosti portal
 *
 * Inrupt Best Practice Compliance Report:
 *  - BP-0: PASS-01 - Vocabulary IRI [https://data.gov.sk/def/ontology/location-mou-ext/] is different from the namespace IRI [https://data.gov.sk/def/ontology/location/] (and there was no need to provide overrides for either).
 *  - BP-1: All [1] terms have 'rdfs:isDefinedBy' triples.
 *  - BP-2: All [1] terms that have 'rdfs:isDefinedBy' triples (of the [1] total terms) are defined by the vocabulary IRI of [https://data.gov.sk/def/ontology/location-mou-ext/].
 */

// We prefix our local variables with underscores to (hopefully!) prevent
// potential names clashes with terms from vocabularies.
const { VocabTerm: _VocabTerm, getLocalStore } = require("@inrupt/solid-common-vocab");
const _DataFactory = require("@rdfjs/data-model");

const { namedNode: _namedNode } = _DataFactory;

function _NS(localName) {
  return _namedNode(`https://data.gov.sk/def/ontology/location/${localName}`);
}

/**
 * Slovak national extension adding terms used in MOU currently not present in Znalosti portal
 *
 * Namespace IRI: [https://data.gov.sk/def/ontology/location/]
 */
const LOCA = {
  PREFIX: "loca",
  NAMESPACE: "https://data.gov.sk/def/ontology/location/",
  PREFIX_AND_NAMESPACE: { "loca": "https://data.gov.sk/def/ontology/location/" },
  NS: _NS,

  // *******************
  // All the Properties.
  // *******************

  /**
   * Informácia o fyzickej adrese / mieste vydania zdroja, dokumentu.
   *
   * This term has [1] label and comment, in the language [sk].
   *
   * Defined by the vocabulary: https://data.gov.sk/def/ontology/location-mou-ext/
   */
  issuedAt: new _VocabTerm(
    _NS("issuedAt"),
    _DataFactory,
    getLocalStore(),
    false
  )
    .addType(_namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#Property"))
    .addIsDefinedBy(_namedNode("https://data.gov.sk/def/ontology/location-mou-ext/"))
    .addLabel(`miesto vydania`, "sk")
    .addComment(`Informácia o fyzickej adrese / mieste vydania zdroja, dokumentu.`, "sk"),

}

module.exports = LOCA;
